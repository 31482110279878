import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import {
  Layout,
  PageHeader,
  Row,
  Col,
  Button,
  Icon,
  Menu,
  Spin,
  Breadcrumb,
  Checkbox,
  DatePicker,
  Upload,
  Badge,
  message,
  Progress,
  Skeleton,
} from 'antd'
import {
  withAuth
} from '../../providers'
import { ReactComponent as Logo } from './logo-opencolonna.svg'
import s from './LoginPage.styl'
import { ReactComponent as AccessIcon } from './access-icon.svg'


const keiboardItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, null, 0, 'cancel']

const {
  Header,
  Content,
  Sider,
  Footer,
} = Layout;

const codeLength = 6

class LoginPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      code: '',
      error: false
    }
  }

  componentWillMount() {
    const { history } = this.props
    
    if (localStorage.getItem('access-token')) {
      history.push('/tables')
    }
  }

  addNumberToCode = (n) => {
    const { code } = this.state

    const newCode = `${code}${n}`

    this.setState({
      code: newCode
    })
    if (newCode.length >= codeLength) this.checkCode(newCode)
  }

  hanldeCodeError = (response) => {
    const { intl } = this.props
    message.error(intl.formatMessage({ id: 'errorOnLogin' }));

    this.setState({
      error: true,
    })

    setTimeout(() => {
      this.setState({
        error: false,
        code: ''
      })
    }, 500);
  }

  handleCodeSuccess = (code) => {
    const {
      history,
    } = this.props

    history.push('/tables')
  }

  checkCode = (code) => {
    const {
      getToken
    } = this.props

    getToken(code)
      .then((res) => {
        if (res.error) {
          this.hanldeCodeError(code)
        } else {
          this.handleCodeSuccess(code)
        }
      })
      .catch((error) => {
        this.hanldeCodeError(code)
      })
  }

  cancelCode = () => {
    this.setState({
      code: ''
    })
  }

  render() {
    const {
      loading
    } = this.props
    const {
      code,
      error
    } = this.state

    return (
      <Layout style={{ height: '100%', minHeight: '100vh' }}>
        <Spin spinning={loading}>
          <Content>
            <Row className={s.row}>
              <Col span={12} className={s.col}>
                <AccessIcon />            
              </Col>
              <Col span={12} className={s.col}>
                <div className={s.codeWrapper}>
                  <FormattedMessage id="digitCodeToAccess" />

                  <div className={error ? s.codePlaceholderError : s.codePlaceholder}>
                    {
                      Array(codeLength).fill().map((e, i) => (
                        <span
                          key={`code-bullet-placeholder-${i * 1}`}
                          className={
                            code.length > i
                              ? s.codePlaceholderItemFilled
                              : s.codePlaceholderItem
                          }
                        />
                      ))
                    }
                  </div>

                  <div className={s.keyboard}>
                    {
                      keiboardItems.map((e, i) => {
                        if (e === null) {
                          return (
                            <div className={s.keyboardItem} key={`keyboard-item-${i * 1}`} />
                          )
                        }
                        if (e === 'cancel') {
                          return (
                            <div className={s.keyboardItem} key={`keyboard-item-${i * 1}`}>
                              <button className={s.buttonClear} onClick={this.cancelCode}>
                                <FormattedMessage id="cancel" />
                              </button>
                            </div>
                          )
                        } 
                        return (
                          <div className={s.keyboardItem} key={`keyboard-item-${i * 1}`}>
                            <Button
                              shape="circle"
                              size="large"
                              onClick={() => { this.addNumberToCode(e) }}
                            >
                              {e}
                            </Button>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </Content>
        </Spin>
      </Layout>
    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
  getToken: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default withRouter(injectIntl(withAuth(LoginPage)))
