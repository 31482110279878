import { RSAA } from 'redux-api-middleware'
import idx from 'idx'
import findIndex from 'lodash.findindex'
import { getApiEndpoint } from '../utils/ApiUrls'
import { manageResponseFetch, updateOrdersWithGoCourseStatus, getDefaultHeaders } from '../utils/Functions'

export const GET_FOOD_ORDER_REQUEST = 'food/GET_ORDER_REQUEST'
export const GET_FOOD_ORDER_SUCCEDED = 'food/GET_ORDER_SUCCEDED'
export const GET_FOOD_ORDER_FAILED = 'food/GET_ORDER_FAILED'

export const CREATE_FOOD_ORDER_REQUEST = 'food/CREATE_ORDER_REQUEST'
export const CREATE_FOOD_ORDER_SUCCEDED = 'food/CREATE_ORDER_SUCCEDED'
export const CREATE_FOOD_ORDER_FAILED = 'food/CREATE_ORDER_FAILED'

export const UPDATE_FOOD_ORDER_REQUEST = 'food/UPDATE_ORDER_REQUEST'
export const UPDATE_FOOD_ORDER_SUCCEDED = 'food/UPDATE_ORDER_SUCCEDED'
export const UPDATE_FOOD_ORDER_FAILED = 'food/UPDATE_ORDER_FAILED'

export const DELETE_FOOD_ORDER_REQUEST = 'food/DELETE_ORDER_REQUEST'
export const DELETE_FOOD_ORDER_SUCCEDED = 'food/DELETE_ORDER_SUCCEDED'
export const DELETE_FOOD_ORDER_FAILED = 'food/DELETE_ORDER_FAILED'

export const GO_FOOD_ORDER_REQUEST = 'food/GO_ORDER_REQUEST'
export const GO_FOOD_ORDER_SUCCEDED = 'food/GO_ORDER_SUCCEDED'
export const GO_FOOD_ORDER_FAILED = 'food/GO_ORDER_FAILED'

export const getFoodOrders = ({ tableId }) => {
  const endpoint = getApiEndpoint('createFoodOrder', { tableId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
      types: [
        GET_FOOD_ORDER_REQUEST,
        {
          type: GET_FOOD_ORDER_SUCCEDED,
          payload: (action, state, res) => (
            new Promise((resolve, reject) => {
              manageResponseFetch(action, state, res)
                .then((orders) => {
                  resolve({
                    orders,
                    tableId,
                  })
                })
            })
          )
        },
        GET_FOOD_ORDER_FAILED
      ]
    }
  }
}

export const createNewFoodOrder = ({ tableId, courses }) => {
  const endpoint = getApiEndpoint('createFoodOrder', { tableId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
      },
      body: JSON.stringify({
        courses,
        tableId
      }),
      types: [
        CREATE_FOOD_ORDER_REQUEST,
        {
          type: CREATE_FOOD_ORDER_SUCCEDED,
          payload: (action, state, res) => (
            new Promise((resolve, reject) => {
              manageResponseFetch(action, state, res)
                .then((order) => {
                  resolve({
                    order,
                    tableId,
                  })
                })
            })
          )
        },
        CREATE_FOOD_ORDER_FAILED
      ]
    }
  }
}


export const goFoodOrder = ({ courseId, tableId }) => {
  const endpoint = getApiEndpoint('goCourse', { courseId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
      },
      types: [
        {
          type: GO_FOOD_ORDER_REQUEST,
          payload: (action, state, res) => ({
            tableId,
            courseId,
          })
        },
        {
          type: GO_FOOD_ORDER_SUCCEDED,
          payload: (action, state, res) => (
            new Promise((resolve, reject) => {
              manageResponseFetch(action, state, res)
                .then((course) => {
                  resolve({
                    course,
                    tableId,
                    courseId: course.id,
                  })
                })
            })
          )
        },
        GO_FOOD_ORDER_FAILED
      ]
    }
  }
}

export const foodOrders = (state = {}, { type, payload }) => {
  const newState = {
    ...state,
  }

  switch (type) {
    case GET_FOOD_ORDER_SUCCEDED:
      newState[payload.tableId] = payload.orders
      return newState
    case GO_FOOD_ORDER_SUCCEDED:
      newState[payload.tableId] = updateOrdersWithGoCourseStatus(
        state[payload.tableId],
        payload.courseId,
        payload.course
      )
      return newState
    case GO_FOOD_ORDER_REQUEST:
      newState[payload.tableId] = updateOrdersWithGoCourseStatus(
        state[payload.tableId],
        payload.courseId,
        {
          status: 'loading'
        }
      )
      return newState
    default:
      return state
  }
}
