import { RSAA } from 'redux-api-middleware'
import idx from 'idx'
import { getApiEndpoint } from '../utils/ApiUrls'
import { manageResponseFetch, getDefaultHeaders } from '../utils/Functions'

export const GET_BEVERAGE_AREA_REQUEST = 'beverageAreas/GET_BEVERAGE_AREA_REQUEST'
export const GET_BEVERAGE_AREA_SUCCEDED = 'beverageAreas/GET_BEVERAGE_AREA_SUCCEDED'
export const GET_BEVERAGE_AREA_FAILED = 'beverageAreas/GET_BEVERAGE_AREA_FAILED'

export const getBeverageAreas = () => {
  const endpoint = getApiEndpoint('beveragesAreas')

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
      types: [
        GET_BEVERAGE_AREA_REQUEST,
        {
          type: GET_BEVERAGE_AREA_SUCCEDED,
          payload: manageResponseFetch
        },
        GET_BEVERAGE_AREA_FAILED
      ]
    }
  }
}

export const beverageAreas = (state = [], action) => {
  switch (action.type) {
    case GET_BEVERAGE_AREA_REQUEST:
      return state
    case GET_BEVERAGE_AREA_SUCCEDED:
      return action.payload 
    case GET_BEVERAGE_AREA_FAILED:
      return []
    default:
      return state
  }
}

const getBeveragesFromAreas = (areas) => {
  const beverages = []

  const addItemToList = (area) => {
    idx(area, (_) => _.beverages).map((beverage) => {
      beverages.push(beverage)
    })
  }

  areas.map((area) => {
    if (idx(area, a => a.childs && a.childs.length > 0)) {
      idx(area, (_) => _.childs).map((child) => {
        addItemToList(child)
      })
    } else {
      addItemToList(area)
    }
  })

  return beverages
}

export const beverages = (state = [], action) => { 
  switch (action.type) {
    case GET_BEVERAGE_AREA_REQUEST:
      return state
    case GET_BEVERAGE_AREA_SUCCEDED:
      return getBeveragesFromAreas(action.payload) || state
    case GET_BEVERAGE_AREA_FAILED:
      return []
    default:
      return state
  }
}
