import { connect } from 'react-redux'
import { changeLocale } from '../data/Locale'

const mapStateToProps = (state) => ({
  locale: state.locale,
})

const mapDispatchToProps = (dispatch) => ({
  onLocaleChange: (lang) => dispatch(changeLocale(lang)),
})

const withLocale = component => connect(mapStateToProps, mapDispatchToProps)(component)

export default withLocale
