import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import idx from 'idx';
import { uid } from 'react-uid';
import {
  Form,
  Input,
  Button,
  Select,
  notification,
} from 'antd'
import { getApiEndpoint } from '../../utils/ApiUrls';
import s from './InfoClientForm.styl';

class InfoClientFormDynamic extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }
  }


  handleCreate = e => {
    const { form } = this.props
    e.preventDefault()
    
    form.validateFields([
      'name',
      'surname',
      'company',
      'email',
      'telephoneNumber',
      'VATNumber',
      'allergen',
      'note',
    ], (err, values) => {
      if (!err) {
        const allergen = idx(values, v => v.allergen.map((id) => (
          {
            id
          }
        )))

        this.updateClient({
          ...values,
          allergen
        })
      } else {
        console.log('Validation problem: ', values);
      }
    });
  }

  updateClient = (update) => {
    const { onUpdateClientSuccess, intl, client } = this.props
    const endpoint = getApiEndpoint('client', { clientId: client.id });

    this.setState({
      loading: true,
    })

    fetch(`${endpoint}`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        ...client,
        ...update,
      }),
    })
      // .then(res => res.json())
      .then(res => {
        this.setState({
          loading: false
        })

        onUpdateClientSuccess(client)
      })
      .catch(error => {
        console.log('err', error)

        notification.error({
          message: intl.formatMessage({ id: 'error' }),
          description: intl.formatMessage({ id: 'genericError' }),
        });

        this.setState({
          loading: false
        })
      });
  }

  render() {
    const { loading } = this.state;
    const {
      client,
      allergens,
      intl,
      form,
      onCancel,
    } = this.props;

    return (
      <div className={s.wrapper}>
        <Form onSubmit={this.handleCreate}>
          <div className={s.inputWrapper}>
            <Form.Item>
              {
                form.getFieldDecorator('name', {
                  initialValue: idx(client, _ => _.name),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'nameInputValidation' }),
                    }, 
                  ]
                })(
                  <Input
                    name="name"
                    type="text"
                    size="large"
                    placeholder={intl.formatMessage({ id: 'name' })}
                  />
                )
              }
            </Form.Item>
          </div>

          <div className={s.inputWrapper}>
            <Form.Item>
              {
                form.getFieldDecorator('surname', {
                  initialValue: idx(client, _ => _.surname),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'surnameInputValidation' }),
                    }, 
                  ]
                })(
                  <Input
                    size="large"
                    placeholder={intl.formatMessage({ id: 'surname' })}
                  />
                )
              }
            </Form.Item>
          </div>

          <div className={s.inputWrapper}>
            <Form.Item>
              {
                form.getFieldDecorator('company', {
                  initialValue: idx(client, _ => _.company),
                })(
                  <Input
                    size="large"
                    placeholder={intl.formatMessage({ id: 'company' })}
                  />
                )
              }
            </Form.Item>
          </div>

          <div className={s.inputWrapper}>
            <Form.Item>
              {
                form.getFieldDecorator('VATNumber', {
                  initialValue: idx(client, _ => _.VATNumber),
                })(
                  <Input
                    size="large"
                    placeholder={intl.formatMessage({ id: 'VATNumber' })}
                  />
                )
              }
            </Form.Item>
          </div>
          
          <div className={s.inputWrapper}>
            <Form.Item>
              <Input
                disabled
                size="large"
                placeholder={intl.formatMessage({ id: 'agreement' })}
              />
            </Form.Item>
          </div>

          <div className={s.inputWrapper}>
            <Form.Item>
              {
                form.getFieldDecorator('email', {
                  initialValue: idx(client, _ => _.email),
                  rules: [
                    {
                      type: 'email',
                      message: intl.formatMessage({ id: 'emailInputFormatValidation' }),
                    },
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'emailInputValidation' }),
                    }, 
                  ]
                })(
                  <Input
                    size="large"
                    placeholder={intl.formatMessage({ id: 'email' })}
                  />
                )
              }
            </Form.Item>
          </div>

          <div className={s.inputWrapper}>
            <Form.Item>
              {
                form.getFieldDecorator('telephoneNumber', {
                  initialValue: idx(client, _ => _.telephoneNumber),
                  rules: []
                })(
                  <Input
                    size="large"
                    placeholder={intl.formatMessage({ id: 'phone' })}
                  />
                )
              }
            </Form.Item>
          </div>

          <div className={s.inputWrapper}>
            <Form.Item>
              {
                form.getFieldDecorator('allergen', {
                  initialValue: idx(client, _ => _.allergens.map(({ id }) => id)),
                  rules: []
                })(
                  <Select
                    size="large"
                    mode="multiple"
                    optionFilterProp="title"
                    placeholder={intl.formatMessage({ id: 'selectAllergens' })}
                  >
                    { allergens.map((allergen) => (
                      <Select.Option
                        key={uid(allergen)}
                        value={allergen.id}
                        title={allergen.name}
                      >
                        {allergen.name}
                      </Select.Option>
                    ))}
                  </Select>
                )
              }
            </Form.Item>
          </div>

          <div className={s.inputWrapper}>
            <Form.Item>
              {
                form.getFieldDecorator('note', {
                  initialValue: idx(client, _ => _.note),
                  rules: []
                })(
                  <Input.TextArea
                    autosize={{ minRows: 4, maxRows: 8 }}
                    size="large"
                    placeholder={intl.formatMessage({ id: 'insertClientNote' })}
                  />
                )
              }
            </Form.Item>
          </div>
          
          
          <footer className={s.formFooter}>
            {
              onCancel ? (
                <button
                  type="button"
                  className={s.buttonDelete}
                  onClick={onCancel}
                >
                  <FormattedMessage id="cancelAndClose" />
                </button>
              ) : (
                <div />
              )
            }

            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              <FormattedMessage id="updateClient" />
            </Button>
          </footer>
        </Form>
      </div>
    )
  }
}

InfoClientFormDynamic.propTypes = {
  intl: PropTypes.shape().isRequired,
  client: PropTypes.shape(),
  form: PropTypes.shape().isRequired,
  onUpdateClientSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  allergens: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
InfoClientFormDynamic.defaultProps = {
  client: null,
  onCancel: null,
};

const InfoClientForm = Form.create({ name: 'new_client_form' })(injectIntl(InfoClientFormDynamic));

export default InfoClientForm;
