import { connect } from 'react-redux'
import idx from 'idx'
import { getToken } from '../data/Auth'

const mapStateToProps = (state) => ({
  loading: idx(state, s => s.loading.login),
})

const mapDispatchToProps = (dispatch) => ({
  getToken: (code) => dispatch(getToken(code)),
})

const withAuth = component => connect(mapStateToProps, mapDispatchToProps)(component)

export default withAuth
