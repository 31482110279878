import { connect } from 'react-redux'
import { getOptions } from '../data/Options'

const mapStateToProps = (state) => ({
  user: state.user,
  loading: state.loading.options,
})

const mapDispatchToProps = (dispatch) => ({
  getOptions: () => dispatch(getOptions()),
})

const withUser = component => connect(mapStateToProps, mapDispatchToProps)(component)

export default withUser
