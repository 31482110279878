import React, { Component, Fragment } from 'react'
import idx from 'idx'
import { uid } from 'react-uid'
import { withRouter } from 'react-router-dom'
import {
  injectIntl,
  FormattedMessage,
  FormattedNumber
} from 'react-intl'
import PropTypes from 'prop-types'
import {
  Button,
  Spin,
} from 'antd'
import {
  PayOutAmountForm,
  PayOutMethodForm,
} from '..'
import {
  ClientSelectionForm,
} from '../../containers'
import s from './PayOut.styl'
import {
  withCheckoutContext,
} from '../../containers/TableCashDesk/TableCashDesk'

class PayOut extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      payments: [],
      client: null,
      loading: false,
    }
  }

  handleSelectClient = (client) => {
    console.log('client', client)
    this.setState({
      client,
    })
  }

  handleUpdateClient = (client) => {
    console.log(client)

    this.setState({
      client,
    })
  }

  handleAddPayment = (payment) => {
    const { payments } = this.state
    const newPayments = [
      ...payments,
      payment
    ]

    this.setState({
      payments: newPayments
    })

    return newPayments
  }

  
  handleFinishToPay = (billingMethod) => {
    const {
      createBill,
      productList,
      toPay,
      billLength,
      dividedBy,
      match,
      refreshTableCheckout,
    } = this.props
    const { tableId } = match.params
    const { payments, client = null } = this.state

    const bill = {
      billingMethod,
      payments,
      productList,
      paidTotal: toPay,
      client,
      dividedBy,
    }

    this.setState({
      loading: true
    })

    createBill({
      tableId,
      bill
    }).then(() => {
      refreshTableCheckout()

      this.setState({
        loading: false
      })
    })
  }

  render() {
    const {
      client,
      payments,
      loading,
    } = this.state

    const {
      handleChangeValue,
      billLength,
      index,
      toPay,
      maxAmountToPay,
      isLast,
      paymentMethods,
      createBill,
      loading: loadingProps
    } = this.props

    const paymentsAmount = payments.reduce(
      (accumulator, p) => (Number(accumulator) + Number(p.amount)),
      0
    )
    const finishToPay = (toPay - paymentsAmount) === 0
  
    return (
      <Fragment>
        <Spin spinning={loading || loadingProps}>
          <div className={s.header}>
            <h1 className={s.billNumber}>
              <FormattedMessage
                id="bill"
                values={{
                  number: index + 1
                }}
              />
            </h1>
            <PayOutAmountForm
              toPay={toPay}
              disabledChangeAmount={isLast || payments.length > 0}
              handleChangeValue={handleChangeValue}
              maxAmountToPay={maxAmountToPay}
            />
          </div>
          <div className={s.wrapper}>          
            <div className={s.section}>
              <div className={s.sectionInner}>
                <div>
                  <FormattedMessage id="client" />
                </div>
                <ClientSelectionForm
                  client={client}
                  handleUpdateClient={this.handleUpdateClient}
                  onChange={this.handleSelectClient}
                />
              </div>
            </div>

            <div className={s.section}>
              <h3 className={s.sectionTitle}>
                <FormattedMessage id="paymentMethod" />
              </h3>
              
              <div className={s.sectionInner}>
                <PayOutMethodForm
                  onAddPayment={this.handleAddPayment}
                  payments={payments}
                  paymentMethods={paymentMethods}
                  toPay={toPay}
                />
              </div>
            </div>

            {
              finishToPay
              && (
                <div className={s.footer}>
                  <div className={s.footerCol}>
                    <Button
                      type="primary"
                      onClick={
                        () => this.handleFinishToPay('receipt') 
                      }
                    >
                      <FormattedMessage id="receipt" />
                    </Button>
                  </div>
                  <div className={s.footerCol}>
                    <Button
                      type="primary"
                      onClick={
                        () => this.handleFinishToPay('electronicInvoice') 
                      }
                    >
                      <FormattedMessage id="electronicInvoice" />
                    </Button>
                  </div>
                </div>
              )
            }
          </div>
        </Spin>
      </Fragment>
    )
  }
}

PayOut.propTypes = {
  match: PropTypes.shape().isRequired,
  toPay: PropTypes.number.isRequired,
  dividedBy: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  maxAmountToPay: PropTypes.number.isRequired,
  billLength: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  refreshTableCheckout: PropTypes.func.isRequired,
  handleChangeValue: PropTypes.func.isRequired,
  isLast: PropTypes.bool,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  productList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  createBill: PropTypes.func.isRequired,
}
PayOut.defaultProps = {
  isLast: false,
  loading: false,
}

export default withRouter(injectIntl(withCheckoutContext(PayOut)))
