import { RSAA } from 'redux-api-middleware'
import idx from 'idx'
import { getApiEndpoint } from '../utils/ApiUrls'
import { manageResponseFetch, getDefaultHeaders } from '../utils/Functions'

export const GET_PAYMENT_METHODS_REQUEST = 'paymentMethods/GET_PAYMENT_METHODS_REQUEST'
export const GET_PAYMENT_METHODS_SUCCEDED = 'paymentMethods/GET_PAYMENT_METHODS_SUCCEDED'
export const GET_PAYMENT_METHODS_FAILED = 'paymentMethods/GET_PAYMENT_METHODS_FAILED'

export const getPaymentMethods = () => {
  const endpoint = getApiEndpoint('paymentMethods')

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
      types: [
        GET_PAYMENT_METHODS_REQUEST,
        {
          type: GET_PAYMENT_METHODS_SUCCEDED,
          payload: manageResponseFetch
        },
        GET_PAYMENT_METHODS_FAILED
      ]
    }
  }
}

export const paymentMethods = (state = [], action) => { 
  switch (action.type) {
    case GET_PAYMENT_METHODS_REQUEST:
      return state
    case GET_PAYMENT_METHODS_SUCCEDED:
      
      return idx(action, a => a.payload)
    case GET_PAYMENT_METHODS_FAILED:
      return []
    default:
      return state
  }
}
