import React, { Component, Fragment } from 'react'
import {
  Drawer,
  Icon,
  Layout,
  Menu,
  Button,
  Tabs,
  Badge
} from 'antd'
import find from 'lodash.find'
import PropTypes from 'prop-types'
import idx from 'idx'
import { FormattedMessage } from 'react-intl'
import { uid } from 'react-uid';
import { OrderMenuItem } from '..'
import { cleanCoursesByEmpty } from '../../utils/Functions'
import s from './NewFoodOrderDrawer.styl'

const normalizeInitialState = (initialState) => {
  if (!initialState) {
    return [
      {}
    ]
  }

  const newCourses = initialState.courses.map((c) => c.foodList)

  return [
    ...newCourses
  ]
}

const countCoursePlats = (course) => {
  let count = 0
  Object.keys(course).forEach(key => {
    const { quantity } = course[key];
    count += quantity
  })

  return count
}

const findFoodByID = (foodId, allFoods) => find(allFoods, (food) => food.id === foodId)

const countFoodsInArea = (course, allFoods) => {
  let count = 0
  Object.keys(course).map((k, i) => {
    if (findFoodByID(k, allFoods)) {
      count += course[k].quantity
    }
  })

  return count
}

class NewFoodOrderDrawer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      key: 0,
      activeTabKey: 'food-area-order-tab-pane-0',
      courses: [
        {}
      ],
    }
  }

  componentDidUpdate(oldProps) {
    const { visible } = this.props
    
    if (visible && !oldProps.visible) {
      this.resetState()
    }
  }

  onTabsChange = (activeTabKey) => {
    window.dispatchEvent(new Event('resize'));
  
    this.setState({
      activeTabKey
    })
  }

  handleClick = ({ item, key, keyPath }) => {
    this.setState({
      key: parseInt(key, 10)
    })   
  }

  handleConfirmOrder = () => {
    const { courses } = this.state
    const { handleConfirmOrder, initialState } = this.props

    handleConfirmOrder({
      courses: cleanCoursesByEmpty(courses),
      orderId: idx(initialState, (_) => _.id) || null,
    })
    
    this.resetTabPanesActiveKey()
  }

  handleChangeQuantity = (courseIndex, id, v) => {
    const { courses } = this.state
    const newCourse = [...courses]
    
    if (idx(newCourse, _ => _[courseIndex][id])) {
      if (v.quantity === 0) {
        delete newCourse[courseIndex][id]
      } else if (v.quantity !== undefined) {
        newCourse[courseIndex][id].quantity = v.quantity
        if (v.comment) newCourse[courseIndex][id].comment = v.comment
      }
    } else {
      newCourse[courseIndex][id] = {
        quantity: parseInt(v.quantity, 10),
        comment: v.comment
      }
    }

    this.setState({
      courses: newCourse
    })    
  }
  
  addCourse = () => {
    const { courses } = this.state

    this.setState({
      courses: [
        ...courses,
        {}
      ],
      key: courses.length,
    })
  }

  handleNext = () => {
    const { key, courses } = this.state

    if (courses.length === (key + 1)) {
      this.addCourse()
    } else {
      this.setState({
        key: key + 1
      })
    }
  }

  resetState = () => {
    const { initialState } = this.props

    this.setState({
      key: 0,
      courses: initialState ? normalizeInitialState(initialState) : [
        {}
      ],
    })
  }

  getFoodName = (foodId) => {
    const {
      foods,
    } = this.props

    return find(foods, (food) => food.id === foodId)
  }

  resetTabPanesActiveKey = () => {
    this.setState({
      activeTabKey: 'food-area-order-tab-pane-0',
    })
  }

  handleCloseDrawer = () => {
    const {
      handleCloseDrawer,
    } = this.props

    this.resetTabPanesActiveKey()
    handleCloseDrawer()
  }

  render() {
    const { key, courses, activeTabKey } = this.state
    const {
      visible,
      getContainer,
      foodAreas
    } = this.props

    return (
      <Drawer
        placement="right"
        destroyOnClose
        closable={false}
        maskClosable={false}
        maskStyle={{
          left: 0,
        }}
        // zIndex={999}
        width="80%"
        // getContainer={getContainer}
        onClose={this.handleCloseDrawer}
        visible={visible}
        className={s.drawer}
      >
        <Layout
          className={s.mainWrapper}
        >
          <Layout.Sider className={s.sider}>
            <h3 className={s.menuTitle}><FormattedMessage id="order" /></h3>
            <Menu
              onClick={this.handleClick}
              selectedKeys={[`${key}`]}
              mode="inline"
            >
              {
                courses.map((course, index) => (
                  <Menu.Item key={`${index * 1}`}>
                    <FormattedMessage
                      id="exitMenuItem"
                      values={{
                        number: index + 1
                      }}
                    />
                    <Badge
                      style={{
                        backgroundColor: '#666',
                        boxShadow: 'none',
                        color: '#fff',
                        marginLeft: 10
                      }}
                      count={countCoursePlats(course)}
                    />

                    {
                      Object.keys(course).map((k) => {
                        const food = this.getFoodName(k)

                        if (course[k].quantity === 0) {
                          return (null)
                        }
                        
                        return (
                          <div className={s.menuPlate} key={uid(course[k])}>
                            {`${course[k].quantity}x `}
                            {food.name}
                          </div>
                        )
                      })
                    }
                  </Menu.Item>
                ))
              }
            </Menu>
            <button
              className={s.buttonAdd}
              onClick={() => { this.addCourse() }}
            >
              <Icon type="plus" /> 
              <FormattedMessage id="addCourse" />
            </button>
          </Layout.Sider>
          <Layout.Content className={s.content}>
            {
              courses.map((course, courseIndex) => (
                <Tabs
                  type="card"
                  onChange={this.onTabsChange}
                  activeKey={activeTabKey}
                  style={{
                    display: courseIndex === key ? 'block' : 'none' 
                  }}
                  key={uid(`course-${courseIndex}`)}
                >
                  {
                    foodAreas.map((area, i) => (
                      <Tabs.TabPane
                        tab={
                          <Badge
                            className={s.tabBadge}
                            count={countFoodsInArea(course, area.foods)}
                          >
                            <span className={s.tabTitle}>
                              {area.name}
                            </span>
                          </Badge>
                        }
                        //eslint-disable-next-line
                        key={`food-area-order-tab-pane-${i}`}
                      >
                        <div
                          className={s.orderItemsWrapper}
                        >
                          {
                            area.foods.map((f) => (
                              <div
                                className={s.item}
                                key={uid(f)}
                              >
                                <OrderMenuItem
                                  id={f.id}
                                  title={f.name}
                                  area={area.name}
                                  getContainer={getContainer}
                                  quantity={course[f.id] ? course[f.id].quantity : 0}
                                  comment={course[f.id] ? course[f.id].comment : null}
                                  handleChangeQuantity={(v) => {
                                    this.handleChangeQuantity(courseIndex, f.id, v)
                                  }}
                                />
                              </div>
                            ))
                          }
                        </div>
                      </Tabs.TabPane>
                    ))
                  }
                </Tabs>
              ))
            }
          </Layout.Content>
          <div className={s.footer}>
            <button
              onClick={this.handleCloseDrawer}
              className={s.buttonDelete}
            >
              <FormattedMessage id="deleteOrder" />
            </button>

            <Button
              className={s.button}
              onClick={this.handleConfirmOrder}
            >
              <FormattedMessage id="sendOrder" />
            </Button>
            <Button
              type="primary"
              className={s.button}
              onClick={this.handleNext}
            >
              <FormattedMessage id="next" />
            </Button>
          </div>
        </Layout>
      </Drawer>
    )
  }
}

NewFoodOrderDrawer.propTypes = {
  handleConfirmOrder: PropTypes.func.isRequired,
  handleCloseDrawer: PropTypes.func.isRequired,
  getContainer: PropTypes.string,
  foodAreas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  foods: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  visible: PropTypes.bool,
  initialState: PropTypes.shape({}),
}
NewFoodOrderDrawer.defaultProps = {
  getContainer: null,
  visible: false,
  initialState: null,
}

export default NewFoodOrderDrawer
