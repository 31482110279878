import { RSAA } from 'redux-api-middleware'
import idx from 'idx'
import { getApiEndpoint } from '../utils/ApiUrls'
import { manageResponseFetch, getDefaultHeaders } from '../utils/Functions'

export const GET_FOOD_AREA_REQUEST = 'foodAreas/GET_FOOD_AREA_REQUEST'
export const GET_FOOD_AREA_SUCCEDED = 'foodAreas/GET_FOOD_AREA_SUCCEDED'
export const GET_FOOD_AREA_FAILED = 'foodAreas/GET_FOOD_AREA_FAILED'

export const getFoodAreas = () => {
  const endpoint = getApiEndpoint('foodsAreas')

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'GET',
      headers: {
        ...getDefaultHeaders()
      },
      types: [
        GET_FOOD_AREA_REQUEST,
        {
          type: GET_FOOD_AREA_SUCCEDED,
          payload: manageResponseFetch
        },
        GET_FOOD_AREA_FAILED
      ]
    }
  }
}


export const foodAreas = (state = [], action) => {
  switch (action.type) {
    case GET_FOOD_AREA_REQUEST:
      return state
    case GET_FOOD_AREA_SUCCEDED:
      return action.payload
    case GET_FOOD_AREA_FAILED:
      return []
    default:
      return state
  }
}

const getFoodsFromAreas = (areas) => {
  const foods = []
  areas.map((area) => {
    area.foods.map((food) => {
      foods.push(food)
    })
  })

  return foods
}

export const foods = (state = [], action) => { 
  switch (action.type) {
    case GET_FOOD_AREA_REQUEST:
      return state
    case GET_FOOD_AREA_SUCCEDED:
      
      return getFoodsFromAreas(action.payload) || state
    case GET_FOOD_AREA_FAILED:
      return []
    default:
      return state
  }
}
