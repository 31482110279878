import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Layout,
  Spin,
  Divider,
} from 'antd'
import {
  withBookingsPage,
} from '../../providers'
import {
  BookingsList,
  EditBookingDrawer,
} from '../../components'
import s from './ReservationsPage.styl'

class ReservationsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openPanel: false,
      currentBooking: false,
    }
  }

  componentDidMount() {
    const { getBookings } = this.props
    
    getBookings()
  }

  onClickReservation = (currentBooking) => {
    const { loading } = this.props

    console.log('onClickReservation', currentBooking)

    this.setState({
      currentBooking,
      openPanel: true,
    })
  }

  render() {
    const { openPanel, currentBooking } = this.state
    const { bookings, loading, intl } = this.props

    return (
      <Spin spinning={loading}>
        <Layout className={s.wrapper}>
          <div className={s.bookingListWrapper}>
            <h5 className={s.title}>
              <FormattedMessage id="bookingList" />
            </h5>
            <Divider />
            <BookingsList
              bookings={bookings}
              showTable
              action={this.onClickReservation}
              actionText={intl.formatMessage({ id: 'edit' })}
            />
          </div> 
          
          <EditBookingDrawer
            visible={openPanel}
            booking={currentBooking}
          />
        </Layout>
      </Spin>
    );
  }
}

ReservationsPage.propTypes = {
  bookings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool,
  getBookings: PropTypes.func.isRequired,
  intl: PropTypes.shape({}).isRequired
}

ReservationsPage.defaultProps = {
  loading: false,
}

export default injectIntl(withBookingsPage(ReservationsPage));
