import React, { Component } from 'react'
import PropTypes from 'prop-types'
import idx from 'idx'
import { uid } from 'react-uid'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  Button,
  Select
} from 'antd'
import { withTablesPage } from '../../providers'
import s from './MovingTableForm.styl'

const TableOption = ({ table }) => {
  const getStatusIcon = () => {
    if (table.status === 'free') {
      return (<div className={s.statusIconFree} />)
    }

    if (table.owner.short === 'me') {
      return (<div className={s.statusIconUsed} />)
    }

    return (
      <div className={s.statusIconBusy} />
    )
  }

  return (
    <div className={s.optionContent}>
      <div className={s.optionContentName}>
        <FormattedMessage id="table" />
        {' '}
        {table.name}
      </div>
      <div className={s.optionContentStatus}>
        { getStatusIcon()}
        <FormattedMessage id="status" />
        {': '}
        <FormattedMessage id={table.status} />
        {
          idx(table, (t) => t.usedSeats > 0)
          && (
            <div className={s.usedSeats}>
              {' '}
              <FormattedMessage id="copertiUsati" values={{ usedSeats: table.usedSeats }} />
            </div>
          )
        }
      </div>
    </div>
  )
}

TableOption.propTypes = {
  table: PropTypes.shape({}).isRequired
}

class MovingTableForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: false
    }
  }

  componentDidMount() {
    const {
      getTables,
    } = this.props

    getTables()
  }

  onSelectTable = (value) => {
    this.setState({
      selected: value,
    })
  }

  render() {
    const {
      onCancel,
      onClickMove,
      tables,
      intl
    } = this.props

    const { selected } = this.state

    return (
      <div className={s.wrapper}>
        <div className={s.movingMsg}>
          <FormattedMessage id="movingTableText" />
        </div>
        <div className={s.movingMsgBig}>
          <FormattedMessage id="movingTableSelectDestination" />
        </div>
    
        <div className={s.selectWrapper}>
          <Select
            className={s.select}
            onChange={this.onSelectTable}
            size="large"
            placeholder={intl.formatMessage({ id: 'selectTable' })}
          >
            {
              tables.map((table) => (
                <Select.Option
                  value={table.id}
                  key={uid(table)}
                >
                  <TableOption table={table} />
                </Select.Option>
              ))
            }
          </Select>
        </div>
    
        <div className={s.movingMsgButtons}>
          <Button size="large" block onClick={onCancel}>
            <FormattedMessage id="cancel" />
          </Button>
          
          <Button type="primary" size="large" block onClick={() => { onClickMove(selected) }} disabled={!selected}>
            <FormattedMessage id="moveTable" />
          </Button>
        </div>
      </div>
    )
  }
}

MovingTableForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onClickMove: PropTypes.func.isRequired,
  tables: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTables: PropTypes.func.isRequired,
  intl: PropTypes.shape().isRequired,
}
MovingTableForm.defaultProps = {
}

export default injectIntl(withTablesPage(MovingTableForm))
