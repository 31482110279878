import React, { Component, Fragment } from 'react'
import { uid } from 'react-uid'
import {
  Input,
} from 'antd'
import {
  FormattedMessage,
  FormattedNumber
} from 'react-intl'
import PropTypes from 'prop-types'

import s from './PayOutMethodForm.styl'

import { ReactComponent as CashIcon } from './cash.svg'

class PayOutMethodForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inputValue: Number(props.toPay).toFixed(2),
      maxInputValue: props.toPay,
    }
  }

  componentDidUpdate(oldProps) {
    const { toPay, payments } = this.props

    if (oldProps.payments !== payments) {
      const paymentsAmount = payments.reduce(
        (accumulator, p) => (Number(accumulator) + Number(p.amount)),
        0
      )
      const newMax = toPay - paymentsAmount

      this.updateNewMax(newMax)
    }
  }

  updateNewMax = (newMax) => {
    this.setState({
      maxInputValue: newMax,
      inputValue: Number(newMax).toFixed(2),
    })
  }

  handleClickPayment = (paymentMethod) => {
    const { inputValue } = this.state
    const { onAddPayment } = this.props

    onAddPayment({
      paymentMethod,
      amount: inputValue,
    })
  }

  handleChangeInput = (e) => {
    const { value } = e.target

    this.setState({
      inputValue: value
    })
  }

  normalizeInputValue = (e) => {
    const { maxInputValue } = this.state
    let { inputValue } = this.state

    if (inputValue > maxInputValue) {
      inputValue = maxInputValue
    } else if (inputValue < 0) {
      inputValue = 1
    }


    this.setState({
      inputValue: Number(inputValue).toFixed(2)
    })
  }

  render() {
    const {
      inputValue,
      maxInputValue,
    } = this.state

    const {
      payments,
      paymentMethods
    } = this.props

    const finishToPay = maxInputValue === 0
  
    return (
      <Fragment>
        <ul className={s.paymentMethodList}>
          <div className={s.paymentMethodLabel}>
            <FormattedMessage id="inPayment" />
            <div className={s.paymentMethodValue}>
              <Input
                type="number"
                value={inputValue}
                prefix="€"
                step={0.01}
                disabled={finishToPay}
                onChange={this.handleChangeInput}
                onBlur={this.normalizeInputValue}
                onPressEnter={this.normalizeInputValue}
                max={maxInputValue}
                min={0}
              />
            </div>
          </div>
          {
            paymentMethods.map((method) => (
              <li className={s.paymentMethodItem} key={uid(method)}>
                <button
                  disabled={finishToPay}
                  className={s.paymentMethodButton}
                  onClick={() => this.handleClickPayment(method)}
                >
                  {method.title}
                  <CashIcon />
                </button>
              </li>
            ))
          }
        </ul>
        
        <ul className={s.paymentList}>
          {
            payments.map((payment) => (
              <li className={s.paymentItem} key={uid(payment)}>
                <div className={s.paymentItemAmout}>
                  <FormattedNumber
                    value={payment.amount}
                    style="currency"
                    currency={process.env.CURRENCY}
                  />
                </div>
                <div className={s.paymentItemMethod}>
                  <FormattedMessage id="paydBy" />
                  {': '}
                  <div className={s.paymentItemMethodName}>
                    {payment.paymentMethod.title}
                  </div>
                </div>
              </li>
            ))
          }
        </ul>
      </Fragment>
    )
  }
}

PayOutMethodForm.propTypes = {
  toPay: PropTypes.number.isRequired,
  payments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onAddPayment: PropTypes.func.isRequired,
}

export default PayOutMethodForm
