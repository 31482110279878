import React from 'react'
import {
  Route,
  Switch,
} from 'react-router-dom'
import {
  Layout,
} from 'antd'
import {
  TablesPage,
  TablePage,
} from '../../pages'

const TablesArea = () => (
  <Layout style={{ minHeight: '100%' }}>
    <Switch>
      <Route exact path="/tables" component={TablesPage} />
      <Route exact path="/tables/:tableId/:subpage?" component={TablePage} />
    </Switch>
  </Layout>
)

TablesArea.propTypes = {
}

TablesArea.defaultProps = {
}

export default TablesArea
