import React, { Component } from 'react'
import PropTypes from 'prop-types'
import idx from 'idx'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import {
  Row,
  Col,
  Radio,
  Divider,
  Button,
  Layout,
} from 'antd'
import {
  BookingsList,
  NumericRadioInput,
} from '..'
import {
  ClientSelectionForm,
  MovingTableForm,
} from '../../containers'
import s from './TableStatus.styl'

class TableStatus extends Component {
  constructor(props) {
    super(props)

    this.state = {
      moving: false
    }
  }

  handleChangeNumber = (usedSeats) => {
    const { table, handleUseTable } = this.props

    handleUseTable(table.id, { usedSeats })
  }

  handleChangeStatus = (e) => {
    const { target: { value } } = e
    const {
      table,
      handleFreeTable,
      handleUseTable,
    } = this.props

    if (value !== 'free') {
      handleUseTable(table.id, {
        usedSeats: 1
      })
    }

    if (value === 'free') {
      handleFreeTable(table.id)
    }
  }

  handleSelectClient = (client) => {
    const {
      handleSetClient,
      handleRemoveClient,
    } = this.props

    if (client && client.id) {
      handleSetClient({ clientId: client.id })
    } else {
      handleRemoveClient()
    }
  }

  onClickReservation = ({ client }) => {
    const { handleSetClient } = this.props
    
    handleSetClient({
      clientId: client.id
    })
  }

  handleOpenMovingTable = () => {
    this.setState({
      moving: true
    })
  }

  handleCloseMovingTable = () => {
    this.setState({
      moving: false
    })
  }

  handleCloseMovingTable = (destinationTableId) => {
    this.setState({
      moving: false
    })
  }

  renderTableMoving = () => {
    const { handleMoveTable } = this.props

    return (
      <Layout className={s.movingTableWrapper}>
        <MovingTableForm
          onCancel={this.handleCloseMovingTable}
          onClickMove={(destinationTableId) => {
            handleMoveTable(destinationTableId)
              .then(this.handleCloseMovingTable)
          }}
        />
      </Layout>
    )
  }

  render() {
    const {
      table,
      loading,
      fetchingTableClient,
      handleRefreshTable,
      intl,
    } = this.props

    const { moving } = this.state

    if (moving) return this.renderTableMoving()

    return (
      <div className={s.wrapper}>
        <Row>
          <Col span={8}>
            <h5 className={s.title}>
              <FormattedMessage id="tableStatus" />
            </h5>
            <Divider />
            <Radio.Group
              onChange={this.handleChangeStatus}
              value={table.status}
              size="large"
            >
              <Radio className={s.radioItem} value="free">
                <FormattedMessage id="free" />
              </Radio>
              <Radio className={s.radioItem} value="in-use">
                <FormattedMessage id="in-use" />
              </Radio>
            </Radio.Group>
          </Col>
          {
            <Col span={16}>
              <h5 className={s.title}>
                <FormattedMessage id="numOfSeats" />
              </h5>
              <Divider />

              <NumericRadioInput
                maxValue={12}
                value={table.usedSeats}
                onChange={this.handleChangeNumber}
              />

              {
                table.incoming
                && (
                  <div className={s.incomingHour}>
                    <span>
                      <FormattedMessage id="incomingHour" />
                    </span>
                    {moment(table.incoming).format('HH:mm')}
                  </div>
                )
              }
              <div className={s.bookings}>
                <h5 className={s.title}>
                  <FormattedMessage id="client" />
                </h5>
                <Divider />
                <ClientSelectionForm
                  client={table.client}
                  fetching={fetchingTableClient}
                  handleUpdateClient={handleRefreshTable}
                  onChange={this.handleSelectClient}
                />
              </div>
              {
                idx(table, (t) => t.bookings.length > 0) && (
                  <div className={s.bookings}>
                    <h5 className={s.title}>
                      <FormattedMessage id="bookingList" />
                    </h5>
                    <Divider />
                    <BookingsList
                      bookings={table.bookings}
                      action={this.onClickReservation}
                      actionText={intl.formatMessage({ id: 'clientArrived' })}
                    />
                  </div>
                )
              }

              {
                table && table.status === 'in-use'
                && (
                  <div className={s.movingTableButtonWrapper}>
                    <Button type="primary" size="large" onClick={this.handleOpenMovingTable}>
                      <FormattedMessage id="moveTable" />
                    </Button>
                  </div>
                )
              }
            </Col>
          }
        </Row>
      </div>
    )
  }
}

TableStatus.propTypes = {
  handleRefreshTable: PropTypes.func.isRequired,
  handleFreeTable: PropTypes.func.isRequired,
  handleMoveTable: PropTypes.func.isRequired,
  handleUseTable: PropTypes.func.isRequired,
  handleSetClient: PropTypes.func.isRequired,
  handleRemoveClient: PropTypes.func.isRequired,
  table: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
  loading: PropTypes.bool,
  fetchingTableClient: PropTypes.bool,
}
TableStatus.defaultProps = {
  loading: false,
  fetchingTableClient: false,
}

export default injectIntl(TableStatus)
