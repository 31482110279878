import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import idx from 'idx'
import {
  withRouter,
  Link,
  Switch,
  Route,
} from 'react-router-dom'
import {
  Layout,
  Menu,
  Spin,
  Icon,
  Button,
} from 'antd'
import {
  TableStatus,
} from '../../components'
import {
  TableOrders,
  TableCashDesk,
} from '../../containers'
import {
  withTablePage,
} from '../../providers'
import s from './TablePage.styl'

const Loader = () => (
  <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
) 

class TablePage extends Component {
  componentDidMount() {
    this.refreshTable()
  }

  componentDidUpdate(prevProps) {
    const { table, match: { params } } = this.props
    const { table: tableOld } = prevProps

    
    if (!params.subpage) { 
      if (idx(table, t => t.id) && idx(tableOld, t => t.id) !== idx(table, t => t.id)) {
        this.redirectTable()
      }
    }
  }

  redirectTable = () => {
    const { history, table, match: { params } } = this.props
        
    if (table.status === 'in-use') {
      history.replace(`/tables/${params.tableId}/orders`)
    } else {
      history.replace(`/tables/${params.tableId}/status`)
    }
  }

  refreshTable = () => {
    const {
      getTable,
      match: { params }
    } = this.props

    getTable(params.tableId)
  }

  handleSetClient = (client) => {
    const {
      setClient,
      getTable,
      table,
    } = this.props
    
    setClient(table.id, client)
      .then(() => {
        getTable(table.id)
      })
  }

  handleRemoveClient = () => {
    const {
      table,
      removeClient,
    } = this.props
    
    removeClient(table.id)
  }

  renderTableContent = () => {
    const {
      loading,
      updateTable,
      freeTable,
      useTable,
      setClient,
      removeClient,
      table,
      fetchingTableClient,
    } = this.props

    return (
      <Layout.Content className={s.content}>
        <Switch>
          <Route
            exact
            path="/tables/:tableId/status"
            render={(props) => (
              <TableStatus
                table={table}
                handleUpdateTable={updateTable}
                handleFreeTable={freeTable}
                handleUseTable={useTable}
                handleSetClient={this.handleSetClient}
                handleRemoveClient={this.handleRemoveClient}
                handleRefreshTable={this.refreshTable}
                handleChangeStatus={this.handleChangeStatus}
                handleMoveTable={this.handleMoveTable}
                loading={loading}
                fetchingTableClient={fetchingTableClient}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/tables/:tableId/orders"
            render={(props) => (
              <TableOrders
                table={table}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/tables/:tableId/cashout"
            render={(props) => (
              <TableCashDesk
                table={table}
                {...props}
              />
            )}
          />
        </Switch>
      </Layout.Content>
    )
  }

  takeControl = () => {
    const { table, takeControl } = this.props

    takeControl(table.id)
  }

  renderTableLocked = () => {
    const { table, history } = this.props

    const username = idx(table, t => `${t.owner.waiter.name} ${t.owner.waiter.surname}`)

    return (
      <Layout.Content className={s.lockedTableWrapper}>
        <div>
          <div className={s.lockedMsg}>
            <FormattedMessage id="tableLockedMsg" values={{ user: username }} />
          </div>
          <div className={s.lockedMsgBig}>
            <FormattedMessage id="tableLockedTakeOwnershipMsg" />
          </div>

          <div className={s.lockedMsgButtons}>
            <Link to="/tables">
              <Button size="large" block onClick={history.goBack}>
                <FormattedMessage id="cancel" />
              </Button>
            </Link>
            
            <Button type="primary" size="large" block onClick={this.takeControl}>
              <FormattedMessage id="takeControl" />
            </Button>
          </div>
        </div>
      </Layout.Content>
    )
  }

  handleMoveTable = (destinationTableId) => {
    const {
      intl,
      moveTable,
      history,
      match: {
        params: {
          tableId,
        }
      }
    } = this.props
    
    return moveTable({
      tableId,
      destinationTableId,
    })
      .then(() => {
        history.push(`/tables/${destinationTableId}/status`)
        this.refreshTable()
      })
  }

  render() {
    const {
      loading,
      match: {
        params
      },
      table
    } = this.props
    
    // if (loading || table === undefined) {
    //   return (
    //     <Layout>
    //       <div className={s.header}>
    //         <Loader />
    //       </div>
    //     </Layout>
    //   )
    // }

    return (
      <Spin spinning={loading}>
        <div>
          <Layout style={{ height: '100%' }}>
            <div className={s.header}>
              <div className={s.headerLeft}>
                <h1 className={s.title}>
                  <FormattedMessage id="table" />
                  {table.name}
                </h1>
                <div className={s.status}>
                  <FormattedMessage id="status" />
                  {': '}
                  
                  <span className={s.statusValue}>
                    {
                      table.status
                      && (
                        <FormattedMessage id={table.status} />
                      )
                    }
                  </span>
                </div>
              </div>
              <div className={s.headerActions}>
                <Menu
                  mode="horizontal"
                  inlineCollapsed={false}
                  selectedKeys={[params.subpage]}
                >
                  <Menu.Item key="status">
                    <Link to={`/tables/${params.tableId}/status`}>
                      <FormattedMessage id="status" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="orders" disabled={table.status !== 'in-use'}>
                    <Link to={`/tables/${params.tableId}/orders`}>
                      <FormattedMessage id="order" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="cashout" disabled={table.status !== 'in-use'}>
                    <Link to={`/tables/${params.tableId}/cashout`}>
                      <FormattedMessage id="cashOut" />
                    </Link>
                  </Menu.Item>
                </Menu>
              </div>
            </div>
            {
              idx(table, t => t.owner.short === 'other')
                ? this.renderTableLocked()
                : this.renderTableContent()
            }
          </Layout>
        </div>
      </Spin>
    );
  }
}

TablePage.propTypes = {
  getTable: PropTypes.func.isRequired,
  updateTable: PropTypes.func.isRequired,
  freeTable: PropTypes.func.isRequired,
  moveTable: PropTypes.func.isRequired,
  useTable: PropTypes.func.isRequired,
  setClient: PropTypes.func.isRequired,
  removeClient: PropTypes.func.isRequired,
  takeControl: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  table: PropTypes.shape({}).isRequired,
  intl: PropTypes.shape().isRequired,
  loading: PropTypes.bool,
  fetchingTableClient: PropTypes.bool,
}

TablePage.defaultProps = {
  loading: false,
  fetchingTableClient: false,
}

export default injectIntl(withRouter(withTablePage(TablePage)))
