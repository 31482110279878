import { connect } from 'react-redux'
import idx from 'idx'
import { getTableAreas } from '../data/TableAreas'
import { getTables } from '../data/Tables'

const mapStateToProps = (state) => ({
  areas: state.tableAreas,
  tables: state.tables,
  loading: idx(state, s => s.loading.areas),
})

const mapDispatchToProps = (dispatch) => ({
  getAreas: () => dispatch(getTableAreas()),
  getTables: () => dispatch(getTables()),
})

const withTablesPage = component => connect(mapStateToProps, mapDispatchToProps)(component)

export default withTablesPage
