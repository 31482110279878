import { connect } from 'react-redux'
import { getFoodAreas } from '../data/FoodAreas'
import { getBeverageAreas } from '../data/BeverageAreas'
import { createNewFoodOrder, goFoodOrder, getFoodOrders } from '../data/FoodOrders'
import { createNewBeverageOrder, goBeverageOrder, getBeverageOrders } from '../data/BeverageOrders'

const mapStateToProps = (state) => ({
  foodAreas: state.foodAreas,
  foodOrders: state.foodOrders,
  foods: state.foods,
  beverageAreas: state.beverageAreas,
  beverages: state.beverages,
  beverageOrders: state.beverageOrders,
})

const mapDispatchToProps = (dispatch) => ({
  getFoodAreas: () => dispatch(getFoodAreas()),
  getBeverageAreas: () => dispatch(getBeverageAreas()),
  createNewFoodOrder: (o) => dispatch(createNewFoodOrder(o)),
  createNewBeverageOrder: (o) => dispatch(createNewBeverageOrder(o)),
  goBeverageOrder: (o) => dispatch(goBeverageOrder(o)),
  goFoodOrder: (o) => dispatch(goFoodOrder(o)),
  getFoodOrders: (o) => dispatch(getFoodOrders(o)),
  getBeverageOrders: (o) => dispatch(getBeverageOrders(o)),
})

const TableOrders = component => connect(mapStateToProps, mapDispatchToProps)(component)

export default TableOrders
