import React, { Component, Fragment } from 'react'
import {
  Form,
  Input,
  Button
} from 'antd'
import {
  injectIntl,
  FormattedMessage,
  FormattedNumber
} from 'react-intl'
import PropTypes from 'prop-types'
import s from './PayOutAmountForm.styl'

class PayOutAmountForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      edit: false,
    }
  }

  toggleEditAmount = (value) => {
    const { disabledChangeAmount } = this.props
    if (!disabledChangeAmount) {
      this.setState({
        edit: value
      })
    }
  }

  handleFormSubmit = (e) => {
    const { form, handleChangeValue } = this.props

    e.preventDefault()

    form.validateFields([
      'amountValue',
    ], (err, values) => {
      if (!err) {
        handleChangeValue(values.amountValue)
      } else {
        console.log('Validation problem: ', values);
      }
    });
  }

  renderEditForm = () => {
    const {
      toPay,
      maxAmountToPay,
      intl,
      form
    } = this.props

    return (
      <Form className={s.form} onSubmit={this.handleFormSubmit}>
        <Form.Item>
          {
            form.getFieldDecorator('amountValue', {
              initialValue: toPay.toFixed(2),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'nameInputValidation' }),
                }, 
                {
                  type: 'number',
                  validator: (rule, value) => value < maxAmountToPay,
                  message: intl.formatMessage({ id: 'amountIsTooHight' }),
                }, 
                {
                  type: 'number',
                  validator: (rule, value) => value > 0,
                  message: intl.formatMessage({ id: 'amountIsTooLow' }),
                }, 
              ]
            })(
              <Input
                step=".01"
                type="number"
                placeholder={intl.formatMessage({ id: 'name' })}
              />
            )
          }
        </Form.Item>

        <Button type="primary" htmlType="submit">
          <FormattedMessage id="save" />
        </Button>

        <Button type="default" onClick={() => this.toggleEditAmount(false)} htmlType="button">
          <FormattedMessage id="cancel" />
        </Button>
      </Form>
    )
  }

  renderAmount = () => {
    const { toPay, disabledChangeAmount } = this.props

    return (
      <div className={s.form}>
        <div className={s.toPayAmount}>
          <FormattedNumber
            style="currency"
            currency={process.env.CURRENCY}
            value={toPay}
          />
        </div>
        <Button
          disabled={disabledChangeAmount}
          type="default"
          onClick={() => this.toggleEditAmount(true)}
        >
          <FormattedMessage id="editAmount" />
        </Button>
      </div>
    )
  }

  render() {
    const { edit } = this.state

    return (
      <Fragment>
        <div className={s.wrapper}>
          <FormattedMessage id="toPay" />
          {':  '}
          {
            edit
              ? this.renderEditForm()
              : this.renderAmount()
          }
        </div>
      </Fragment>
    )
  }
}

PayOutAmountForm.propTypes = {
  toPay: PropTypes.number.isRequired,
  maxAmountToPay: PropTypes.number.isRequired,
  handleChangeValue: PropTypes.func.isRequired,
  intl: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  disabledChangeAmount: PropTypes.bool,
}

PayOutAmountForm.defaultProps = {
  disabledChangeAmount: false
}

export default Form.create({ name: 'PayOutAmountForm' })(injectIntl(PayOutAmountForm))
