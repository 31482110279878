import React, { Fragment } from 'react'
import idx from 'idx'
import { uid } from 'react-uid'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import {
  Button,
  Card,
  Divider,
} from 'antd'
import s from './OrderCard.styl'

const OrderCard = ({
  order,
  number,
  handleEdit,
  handleGoCourse,
  type,
}) => {
  const getStatusButton = (course) => {
    if (course.status === 'received') {
      return (
        <Button type="primary" onClick={() => { handleGoCourse(course.id) }}>
          <FormattedMessage id="go" />
        </Button>
      )
    }

    if (course.status === 'loading') {
      return (
        <Button type="primary" loading>
          <FormattedMessage id="go" />
        </Button>
      )
    }

    if (course.status === 'printed' || course.status === 'completed') {
      return (
        <Button icon="check">
          {moment.unix(course.printedAt).format('HH:mm')}
        </Button>
      )
    }

    return (<div />)
  }

  return (
    <div className={s.wrapper}>
      <Card
        title={(
          <div>
            <FormattedMessage id="order" />
            {` ${number}`}
          </div>
        )}
        actions={[
          <div>
            <FormattedMessage
              id="sendToKitchen"
              values={{
                time: moment.unix(order.printedAt).format('HH:mm')
              }}
            />
          </div>
        ]}
      >
        {
          idx(order, o => o.courses.map((course, i) => (
            <Fragment key={uid(`${course.id}-${i}`)}>
              <div className={s.course}>
                <div>
                  {
                    course.productList.map((item, n) => (
                      <div key={uid(item)}>
                        {`${item.quantity}x `}
                        {item.product.name}
                        {
                          item.comment
                          && (
                            <div className={s.comment}>
                              {item.comment}
                            </div>
                          )
                        }                         
                      </div>
                    ))
                  }
                </div>
                {getStatusButton(course)}
              </div>
              {
                (i + 1) !== order.courses.length
                && (
                  <Divider />
                )
              }
            </Fragment>
          )))
        }
      </Card>
    </div>
  )
}

OrderCard.propTypes = {
  handleGoCourse: PropTypes.func.isRequired,
  handleEdit: PropTypes.func,
  order: PropTypes.shape({}).isRequired,
  number: PropTypes.number.isRequired,
  type: PropTypes.string,
}
OrderCard.defaultProps = {
  handleEdit: null,
  type: 'food',
}

export default OrderCard
