import { connect } from 'react-redux'
import idx from 'idx'
import {
  getTable,
} from '../data/Table'
import {
  getTableCheckout,
  resetTableCheckout,
  printTableCheckout,
  billTableCheckout,
} from '../data/Checkout'
import {
  getPaymentMethods
} from '../data/PaymentMethods'

const mapStateToProps = (state) => ({
  paymentMethods: state.paymentMethods,
  totalAmount: state.checkout.totalAmount,
  productPaying: state.checkout.productPaying,
  productToPay: state.checkout.productToPay,
  productPaid: state.checkout.productPaid,
  loading: state.loading.checkoutTable,
  table: state.table,
})

const mapDispatchToProps = (dispatch) => ({
  getTable: (table) => dispatch(getTable(table)),
  getTableCheckout: (table) => dispatch(getTableCheckout(table)),
  printTableCheckout: (table) => dispatch(printTableCheckout(table)),
  billTableCheckout: (bill) => dispatch(billTableCheckout(bill)),
  resetTableCheckout: () => dispatch(resetTableCheckout()),
  getPaymentMethods: () => dispatch(getPaymentMethods()),
})

const withTableCheckout = component => connect(mapStateToProps, mapDispatchToProps)(component)

export default withTableCheckout
