import React, { Component } from 'react';
import {
  Tabs,
  Skeleton,
} from 'antd'
import PropTypes from 'prop-types'
import idx from 'idx'
import {
  TablesMap
} from '../../components'
import {
  withTablesPage,
} from '../../providers'
import s from './TablesPage.styl'

const { TabPane } = Tabs

const TabTitle = ({ title }) => (
  <div>
    <span className={s.badge} />
    {title}
  </div>
)

TabTitle.propTypes = {
  title: PropTypes.string.isRequired
}

const getFreshTable = (tables, tableId) => tables.find((t) => t.id === tableId)

class TablesPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeKey: window.localStorage.getItem('TablesPageActiveKey') || '0'
    }
  }

  componentDidMount() {
    const {
      getAreas,
      getTables,
      areas,
    } = this.props

    getAreas()
    getTables()
  }

  onTabChange = (activeKey) => {
    window.localStorage.setItem('TablesPageActiveKey', activeKey)

    this.setState({
      activeKey
    })
  }

  render() {
    const { activeKey } = this.state
    const { areas, loading, tables } = this.props
    
    if (loading) {
      return (
        <div>
          <Skeleton paragraph={{ rows: 4 }} />
        </div>
      )
    }

    return (
      <div>
        <Tabs
          className={s.tabs}
          activeKey={activeKey}
          onChange={this.onTabChange}
          animated={{
            inkBar: true,
            tabPane: false
          }}
        >
          { 
            tables
            && tables.length > 0
            && areas
            && areas.map((a, i) => (
              <TabPane
                tab={a.name}
                key={`${i * 1}`}
              >
                <TablesMap
                  bg={idx(a, _ => _.backgroundImage)}
                  tables={a.tablesCoordinates.map(({ x, y, table }) => (
                    {
                      ...getFreshTable(tables, table.id),
                      x,
                      y,
                    }
                  ))}
                />
              </TabPane>
            ))
          }
        </Tabs>
      </div>
    );
  }
}

TablesPage.propTypes = {
  getAreas: PropTypes.func.isRequired,
  getTables: PropTypes.func.isRequired,
  areas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tables: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool,
}

TablesPage.defaultProps = {
  loading: false,
}

export default withTablesPage(TablesPage)
