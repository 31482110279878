import { RSAA } from 'redux-api-middleware'
import idx from 'idx'
import { getApiEndpoint } from '../utils/ApiUrls'
import { manageResponseFetch, getDefaultHeaders } from '../utils/Functions'

export const GET_CHECKOUT_REQUEST = 'checkout/GET_CHECKOUT_REQUEST'
export const GET_CHECKOUT_SUCCEDED = 'checkout/GET_CHECKOUT_SUCCEDED'
export const GET_CHECKOUT_FAILED = 'checkout/GET_CHECKOUT_FAILED'
export const GET_CHECKOUT_RESET = 'checkout/GET_CHECKOUT_RESET'

export const PRINT_CHECKOUT_REQUEST = 'checkout/PRINT_CHECKOUT_REQUEST'
export const PRINT_CHECKOUT_SUCCEDED = 'checkout/PRINT_CHECKOUT_SUCCEDED'
export const PRINT_CHECKOUT_FAILED = 'checkout/PRINT_CHECKOUT_FAILED'

export const BILL_CHECKOUT_REQUEST = 'checkout/BILL_CHECKOUT_REQUEST'
export const BILL_CHECKOUT_SUCCEDED = 'checkout/BILL_CHECKOUT_SUCCEDED'
export const BILL_CHECKOUT_FAILED = 'checkout/BILL_CHECKOUT_FAILED'

export const resetTableCheckout = () => (
  (dispatch) => {
    dispatch({
      type: GET_CHECKOUT_RESET,
    })
  }
)

export const getTableCheckout = (tableId) => {
  const endpoint = getApiEndpoint('tableCheckout', { tableId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
      types: [
        GET_CHECKOUT_REQUEST,
        {
          type: GET_CHECKOUT_SUCCEDED,
          payload: (action, state, res) => (
            new Promise((resolve, reject) => {
              console.log('getTableCheckout')

              manageResponseFetch(action, state, res)
                .then((output) => {
                  resolve({
                    response: output,
                    table: tableId,
                  })
                })
            })
          )
        },
        GET_CHECKOUT_FAILED
      ]
    }
  }
}

export const billTableCheckout = ({ bill, tableId }) => {
  if (!tableId) {
    return {
      type: BILL_CHECKOUT_FAILED,
    }
  }

  const endpoint = getApiEndpoint('tableBillsCheckout', { tableId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      headers: {
        ...getDefaultHeaders(),
      },
      body: JSON.stringify({
        ...bill,

        table: {
          id: tableId,
        }
      }),
      method: 'POST',
      types: [
        BILL_CHECKOUT_REQUEST,
        BILL_CHECKOUT_SUCCEDED,
        BILL_CHECKOUT_FAILED
      ]
    }
  }
}

export const printTableCheckout = (tableId) => {
  const endpoint = getApiEndpoint('tablePrintCheckout', { tableId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
      },
      types: [
        PRINT_CHECKOUT_REQUEST,
        PRINT_CHECKOUT_SUCCEDED,
        PRINT_CHECKOUT_FAILED
      ]
    }
  }
}

export const checkout = (state = {}, { payload, type }) => {
  switch (type) {
    case GET_CHECKOUT_SUCCEDED:
      return {
        ...payload.response,
      }
    case GET_CHECKOUT_FAILED:
    case GET_CHECKOUT_RESET:
      return {}
    default:
      return state
  }
}
