import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import idx from 'idx';
import {
  Select,
  Icon,
  Drawer,
  Button,
} from 'antd';
import { uid } from 'react-uid'
import { getApiEndpoint } from '../../utils/ApiUrls';
import {
  CreateNewClientForm,
  InfoClientForm,
} from '..';
import s from './ClientSelectionForm.styl';
import { withAllergens } from '../../providers';

class ClientSelectionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
      loading: false,
      newClientDrawer: false,
      infoClientDrawer: false,
    };
  }

  componentDidMount() {
    const { allergens, getOptions } = this.props

    if (allergens.length === 0) {
      getOptions()
    }
  }

  handleSearch = q => {
    const endpoint = getApiEndpoint('clients');

    if (q) {
      this.setState({
        loading: true,
        suggestions: [],
      })

      fetch(`${endpoint}?search=${q}`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json'
        })
      })
        .then(res => res.json())
        .then(res => {
          this.setState({
            loading: false
          })

          this.updateSuggestion(res)
        })
        .catch(err => {
          this.setState({
            loading: false
          })
        });
    }
  }

  updateSuggestion = (suggestions) => {
    this.setState({
      suggestions
    })
  }

  toggleNewClientDrawer = (state) => {
    this.setState({
      newClientDrawer: state
    })
  }

  toggleInfoClientDrawer = (state) => {
    this.setState({
      infoClientDrawer: state
    })
  }

  handleCreateNewClient = (client) => {
    const { onChange } = this.props
    this.setState({
      newClientDrawer: false
    })
    onChange(client)
  }

  handleUpdateClient = (client) => {
    const { handleUpdateClient } = this.props
    this.setState({
      infoClientDrawer: false
    })
    handleUpdateClient(client)
  }

  onInputChange = (json) => {
    const { onChange } = this.props
    const client = json ? JSON.parse(json) : json
    onChange(client)
  }

  renderSearchInput = () => {
    const {
      suggestions,
      loading,
      newClientDrawer,
      infoClientDrawer,
    } = this.state;
    const {
      intl,
      client,
      allergens,
      fetching
    } = this.props;

    return (
      <div className={s.wrapper}>
        <Select
          className={s.select}
          placeholder={intl.formatMessage({ id: 'selectClient' })}
          defaultActiveFirstOption={false}
          showArrow
          value={idx(client, c => c.name && c.surname) ? `${client.name} ${client.surname}` : undefined}
          optionFilterProp="children"
          size="large"
          filterOption={false}
          onSearch={this.handleSearch}
          onChange={this.onInputChange}
          loading={loading || fetching}
          notFoundContent={false}
          showSearch
          allowClear={!!client}
        >
          {
            suggestions
            && suggestions.length > 0
            && suggestions.map((suggestion) => (
              <Select.Option key={uid(suggestion)} value={JSON.stringify(suggestion)}>
                { `${suggestion.name} ${suggestion.surname}`}
              </Select.Option>
            ))
          }
        </Select>
        
        {
          !idx(client, (_) => _.name) ? (
            <Fragment>
              <div className={s.otherwhise}>
                <FormattedMessage id="otherwhise" />
              </div>

              <Button
                type="primary"
                size="large"
                onClick={() => this.toggleNewClientDrawer(true)}
              >
                <FormattedMessage id="addNewClient" />
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <div className={s.otherwhise}>
                <FormattedMessage id="otherwhise" />
              </div>

              <Button
                ghost
                type="primary"
                size="large"
                onClick={() => this.toggleInfoClientDrawer(true)}
              >
                <FormattedMessage id="infoClient" />
              </Button>
            </Fragment>
          )
        }
        

        <Drawer
          title={intl.formatMessage({ id: 'createNewClient' })}
          placement="right"
          closable={false}
          maskClosable={false}
          destroyOnClose
          onClose={() => this.toggleNewClientDrawer(false)}
          visible={newClientDrawer}
          width={600}
        >
          <CreateNewClientForm
            onCancel={() => this.toggleNewClientDrawer(false)}
            allergens={allergens}
            onNewClientSuccess={this.handleCreateNewClient}
          />
        </Drawer>

        <Drawer
          title={intl.formatMessage({ id: 'infoClient' })}
          placement="right"
          closable={false}
          maskClosable={false}
          destroyOnClose
          onClose={() => this.toggleInfoClientDrawer(false)}
          visible={infoClientDrawer}
          width={600}
        >
          <InfoClientForm
            onCancel={() => this.toggleInfoClientDrawer(false)}
            client={client}
            allergens={allergens}
            onUpdateClientSuccess={this.handleUpdateClient}
          />
        </Drawer>
      </div>
    );
  }

  render() {
    return this.renderSearchInput()
  }
}

ClientSelectionForm.propTypes = {
  intl: PropTypes.shape().isRequired,
  client: PropTypes.shape(),
  onChange: PropTypes.func,
  getOptions: PropTypes.func.isRequired,
  handleUpdateClient: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  allergens: PropTypes.arrayOf(PropTypes.shape()),
};
ClientSelectionForm.defaultProps = {
  client: null,
  onChange: null,
  fetching: false,
  allergens: [],
};

export default injectIntl(withAllergens(ClientSelectionForm));
