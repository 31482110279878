import { connect } from 'react-redux'
import idx from 'idx'
import {
  getTable,
  updateTable,
  useTable,
  freeTable,
  takeControlOfTable,
  setClient,
  removeClient,
  moveTable,
} from '../data/Table'
import { getFoodAreas } from '../data/FoodAreas'

const mapStateToProps = (state) => ({
  table: state.table,
  loading: idx(state, s => s.loading.table),
  updatingTable: idx(state, s => s.loading.updatingTable),
  fetchingTableClient: idx(state, s => s.loading.tableClient),
  foodAreas: state.foodAreas,
})

const mapDispatchToProps = (dispatch) => ({
  getTable: (table) => dispatch(getTable(table)),
  updateTable: (table) => dispatch(updateTable(table)),
  useTable: (table, params) => dispatch(useTable(table, params)),
  freeTable: (table) => dispatch(freeTable(table)),
  moveTable: (table) => dispatch(moveTable(table)),
  getFoodAreas: () => dispatch(getFoodAreas()),
  takeControl: (table) => dispatch(takeControlOfTable(table)),
  setClient: (table, params) => dispatch(setClient(table, params)),
  removeClient: (table) => dispatch(removeClient(table)),
})

const withTablePage = component => connect(mapStateToProps, mapDispatchToProps)(component)

export default withTablePage
