import React from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'
import {
  Table
} from '..'
import s from './TablesMap.styl'


const TablesMap = ({ tables, bg, tablesSize }) => (
  <div
    className={bg ? s.mainWrapper : s.mainWrapperNoBg}
  >
    {
      bg 
      && <img
        alt="bg"
        src={bg}
        className={s.bgImage}
      />
    }
    {
      tables && tables.map((table) => (
        <Table
          key={uid(table)}
          size={tablesSize}
          table={table}
        />
      ))
    }
  </div>
)

TablesMap.propTypes = {
  tables: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  bg: PropTypes.string,
  tablesSize: PropTypes.string,
}
TablesMap.defaultProps = {
  bg: null,
  tablesSize: 'medium',
}

export default TablesMap
