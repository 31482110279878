import { RSAA } from 'redux-api-middleware'
import idx from 'idx'
import { getApiEndpoint } from '../utils/ApiUrls'
import { manageResponseFetch, getDefaultHeaders } from '../utils/Functions'

export const GET_TABLE_AREAS_REQUEST = 'tableAreas/GET_TABLE_AREAS_REQUEST'
export const GET_TABLE_AREAS_SUCCEDED = 'tableAreas/GET_TABLE_AREAS_SUCCEDED'
export const GET_TABLE_AREAS_FAILED = 'tableAreas/GET_TABLE_AREAS_FAILED'

export const getTableAreas = () => {
  const endpoint = getApiEndpoint('options')

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
      types: [
        GET_TABLE_AREAS_REQUEST,
        {
          type: GET_TABLE_AREAS_SUCCEDED,
          payload: manageResponseFetch
        },
        GET_TABLE_AREAS_FAILED
      ]
    }
  }
}

export const tableAreas = (state = [], action) => {
  switch (action.type) {
    case GET_TABLE_AREAS_REQUEST:
      return []
    case GET_TABLE_AREAS_SUCCEDED:
      return [
        ...idx(action, (a) => a.payload.tableAreas)
      ]
    default:
      return state
  }
}
