import { RSAA } from 'redux-api-middleware'
import idx from 'idx'
import { getApiEndpoint } from '../utils/ApiUrls'
import { manageResponseFetch, getDefaultHeaders } from '../utils/Functions'

export const GET_TABLE_REQUEST = 'table/GET_TABLE_REQUEST'
export const GET_TABLE_SUCCEDED = 'table/GET_TABLE_SUCCEDED'
export const GET_TABLE_FAILED = 'table/GET_TABLE_FAILED'

export const UPDATE_TABLE_REQUEST = 'table/UPDATE_TABLE_REQUEST'
export const UPDATE_TABLE_SUCCEDED = 'table/UPDATE_TABLE_SUCCEDED'
export const UPDATE_TABLE_FAILED = 'table/UPDATE_TABLE_FAILED'

export const TAKE_TABLE_REQUEST = 'table/TAKE_TABLE_REQUEST'
export const TAKE_TABLE_SUCCEDED = 'table/TAKE_TABLE_SUCCEDED'
export const TAKE_TABLE_FAILED = 'table/TAKE_TABLE_FAILED'

export const FREE_TABLE_REQUEST = 'table/FREE_TABLE_REQUEST'
export const FREE_TABLE_SUCCEDED = 'table/FREE_TABLE_SUCCEDED'
export const FREE_TABLE_FAILED = 'table/FREE_TABLE_FAILED'

export const MOVE_TABLE_REQUEST = 'table/MOVE_TABLE_REQUEST'
export const MOVE_TABLE_SUCCEDED = 'table/MOVE_TABLE_SUCCEDED'
export const MOVE_TABLE_FAILED = 'table/MOVE_TABLE_FAILED'

export const USE_TABLE_REQUEST = 'table/USE_TABLE_REQUEST'
export const USE_TABLE_SUCCEDED = 'table/USE_TABLE_SUCCEDED'
export const USE_TABLE_FAILED = 'table/USE_TABLE_FAILED'

export const SET_CLIENT_TABLE_REQUEST = 'table/SET_CLIENT_TABLE_REQUEST'
export const SET_CLIENT_TABLE_SUCCEDED = 'table/SET_CLIENT_TABLE_SUCCEDED'
export const SET_CLIENT_TABLE_FAILED = 'table/SET_CLIENT_TABLE_FAILED'

export const REMOVE_CLIENT_TABLE_REQUEST = 'table/REMOVE_CLIENT_TABLE_REQUEST'
export const REMOVE_CLIENT_TABLE_SUCCEDED = 'table/REMOVE_CLIENT_TABLE_SUCCEDED'
export const REMOVE_CLIENT_TABLE_FAILED = 'table/REMOVE_CLIENT_TABLE_FAILED'

export const getTable = (tableId) => {
  const endpoint = getApiEndpoint('tableInfo', { tableId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
      types: [
        GET_TABLE_REQUEST,
        {
          type: GET_TABLE_SUCCEDED,
          payload: manageResponseFetch
        },
        GET_TABLE_FAILED
      ]
    }
  }
}


export const takeControlOfTable = (tableId) => {
  const endpoint = getApiEndpoint('tableTake', { tableId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
      },
      types: [
        TAKE_TABLE_REQUEST,
        {
          type: TAKE_TABLE_SUCCEDED,
          payload: manageResponseFetch
        },
        TAKE_TABLE_FAILED
      ]
    }
  }
}

export const useTable = (tableId, { usedSeats }) => {
  const endpoint = getApiEndpoint('tableUse', { tableId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
      },
      body: JSON.stringify({
        usedSeats
      }),
      types: [
        USE_TABLE_REQUEST,
        {
          type: USE_TABLE_SUCCEDED,
          payload: manageResponseFetch
        },
        USE_TABLE_FAILED
      ]
    }
  }
}


export const freeTable = (tableId) => {
  const endpoint = getApiEndpoint('tableFree', { tableId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
      },
      types: [
        FREE_TABLE_REQUEST,
        {
          type: FREE_TABLE_SUCCEDED,
          payload: manageResponseFetch
        },
        FREE_TABLE_FAILED
      ]
    }
  }
}

export const moveTable = ({ tableId, destinationTableId }) => {
  const endpoint = getApiEndpoint('tableMove', { tableId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
      },
      body: JSON.stringify({
        destinationTableId,
      }),
      types: [
        MOVE_TABLE_REQUEST,
        {
          type: MOVE_TABLE_SUCCEDED,
          payload: manageResponseFetch
        },
        MOVE_TABLE_FAILED
      ]
    }
  }
}

export const updateTable = ({
  id,
  status,
  usedSeats,
  client,
  incoming,
}) => {
  const endpoint = getApiEndpoint('tableUpdate', { tableId: id })

  const updatedTable = {
    id
  }

  if (status !== undefined) updatedTable.status = status
  if (usedSeats !== undefined) updatedTable.usedSeats = usedSeats
  if (client !== undefined) updatedTable.client = client
  if (incoming !== undefined) updatedTable.incoming = incoming

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'PUT',
      headers: {
        ...getDefaultHeaders(),
      },
      body: JSON.stringify({
        ...updatedTable
      }),
      types: [
        UPDATE_TABLE_REQUEST,
        {
          type: UPDATE_TABLE_SUCCEDED,
          payload: manageResponseFetch
        },
        UPDATE_TABLE_FAILED
      ]
    }
  }
}

export const setClient = (tableId, { clientId }) => {
  const endpoint = getApiEndpoint('tableSetClient', { tableId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
      },
      body: JSON.stringify({
        clientId
      }),
      types: [
        SET_CLIENT_TABLE_REQUEST,
        {
          type: SET_CLIENT_TABLE_SUCCEDED,
          payload: manageResponseFetch
        },
        SET_CLIENT_TABLE_FAILED
      ]
    }
  }
}

export const removeClient = (tableId) => {
  const endpoint = getApiEndpoint('tableRemoveClient', { tableId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
      },
      types: [
        REMOVE_CLIENT_TABLE_REQUEST,
        {
          type: REMOVE_CLIENT_TABLE_SUCCEDED,
          payload: manageResponseFetch
        },
        REMOVE_CLIENT_TABLE_FAILED
      ]
    }
  }
}


export const table = (state = {}, action) => {
  switch (action.type) {
    case GET_TABLE_REQUEST:
      return {}
    case GET_TABLE_SUCCEDED:
    case FREE_TABLE_SUCCEDED:
    case TAKE_TABLE_SUCCEDED:
    case USE_TABLE_SUCCEDED:
    case SET_CLIENT_TABLE_SUCCEDED:
    case REMOVE_CLIENT_TABLE_SUCCEDED:
      return {
        ...action.payload
      }
    case GET_TABLE_FAILED:
    case UPDATE_TABLE_FAILED:
      return {}
    case UPDATE_TABLE_SUCCEDED:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
