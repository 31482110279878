import React, { Component, Fragment, createContext } from 'react'
import idx from 'idx'
import { uid } from 'react-uid'
import xor from 'lodash.xor'
import PropTypes from 'prop-types'
import {
  FormattedMessage,
  FormattedNumber,
  injectIntl
} from 'react-intl'
import { withRouter } from 'react-router-dom'
import {
  Row,
  Col,
  message,
  Checkbox,
  Button,
  Spin,
  Modal,
} from 'antd'
import {
  PayDrawer,
} from '../../components'
import s from './TableCashDesk.styl'
import {
  withTableCheckout
} from '../../providers'

export const CheckoutContext = createContext({
  paymentMethods: [],
  createBill: () => {},
});

export const withCheckoutContext = (ChildComponent) => (
  (props) => (
    <CheckoutContext.Consumer>
      {
        ({ paymentMethods, createBill }) => <ChildComponent
          {...props}
          paymentMethods={paymentMethods}
          createBill={createBill}
        />
      }
    </CheckoutContext.Consumer>
  )
)

const CashItem = ({ product, onChange }) => (
  <Checkbox
    value={product.unique}
    onChange={() => onChange()}
  >
    <div className={s.checkboxItemContent}>
      <div>
        {product.quantity || 1}
        {'x '}
        {product.name}
      </div>
      <div className={s.itemPrice}>
        <FormattedNumber
          style="currency"
          currency={process.env.CURRENCY}
          value={product.price}
        />
      </div>
    </div>
  </Checkbox>
)

CashItem.propTypes = {
  product: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
}
const getTotal = (items) => {
  const total = items.reduce((accumulator, currentValue) => { 
    if (currentValue.price) {
      return accumulator + currentValue.price
    }

    return accumulator
  }, 0)

  return total
}

class TableCashDesk extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checkedItems: [],
      indeterminate: false,
      checkAll: true,
      productPaying: {},
      productList: [],
      open: false,
      dividedBy: 1,
    }
  }

  componentDidMount() {
    const {
      resetTableCheckout,
      getPaymentMethods,
      match,
    } = this.props

    getPaymentMethods()
    resetTableCheckout()
    this.refreshTableCheckout()
  }

  componentDidUpdate(prevProps) {
    if (this.props.productPaying !== prevProps.productPaying) {
      this.handleUpdateProductPayng(this.props.productPaying)
    }

    if (this.props !== prevProps) {
      this.checkIfTableIsToClose()
    }

    if (
      this.props.productPaying !== prevProps.productPaying
      || this.props.productToPay !== prevProps.productToPay
    ) {
      this.setProductToPayAndPaying(this.props.productPaying)
    }

    if (
      idx(this.props, p => p.loading === false)
      && idx(this.props, p => p.productToPay.productList.length === 0)
    ) {
      this.checkIfTableIsToClose()
    }
  }

  checkIfTableIsToClose = () => {
    const {
      table,
      history,
      productPaying,
      productToPay,
    } = this.props

    if (
      table.status === 'free'
    ) {
      history.push('/tables')
    }
  }

  refreshTableCheckout = () => {
    const {
      getTableCheckout,
      match,
      getTable,
    } = this.props
    const { tableId } = match.params

    getTable(tableId)
    getTableCheckout(tableId)
  }

  setProductToPayAndPaying = () => {
    const { productPaying, productToPay } = this.props

    const productPayingUnique = productPaying.productList.map((item, index) => {
      const unique = `paying_${item.id}__${index}`

      return {
        ...item,
        unique,
      }
    })

    const productToPayUnique = productToPay.productList.map((item, index) => {
      const unique = `toPay_${item.id}__${index}`

      return {
        ...item,
        unique,
      }
    })

    const productList = [
      ...productPayingUnique,
      ...productToPayUnique,
    ]

    const checkedItems = idx(productPayingUnique, p => p.length > 0)
      ? productPayingUnique
      : productList

    this.setState({
      productList,
      checkedItems,
    }, this.updateTotal)
  }

  handleUpdateProductPayng = (productPaying) => {
    this.setState({
      productPaying,
      open: productPaying.productList.length > 0,
      dividedBy: idx(productPaying, p => p.dividedBy) || 1,
      totalAmount: idx(productPaying, p => p.totalAmount)
    })
  }

  closeDrawer = () => {
    this.setState({
      open: false,
    })
  }

  onItemSelected = item => {
    const { checkedItems, productList } = this.state
    
    const newCheckedItems = xor(checkedItems, [item])
    const indeterminate = idx(newCheckedItems, c => !!c.length && c.length < productList.length)
    const checkAll = newCheckedItems.length === productList.length

    this.setState({
      checkedItems: newCheckedItems,
      indeterminate,
      checkAll,
    }, this.updateTotal)
  }

  handleCheckAllChange = e => {
    const {
      checkedItems,
      productList,
    } = this.state

    const diff = xor(checkedItems, productList)

    this.setState({
      checkedItems: e.target.checked ? [...checkedItems, ...diff] : [],
      indeterminate: false,
      checkAll: e.target.checked,
    }, this.updateTotal);
  }

  updateTotal = () => {
    const { checkedItems } = this.state
    this.setState({
      total: getTotal(checkedItems)
    })
  }

  handlePrintTableCheckout = () => {
    const { printTableCheckout, match, intl } = this.props
    const { tableId } = match.params


    printTableCheckout(tableId)
      .then((res) => {
        if (res.error) {
          message.error(intl.formatMessage({ id: 'genericError' }))
        } else {
          message.success(intl.formatMessage({ id: 'printSuccessfull' }))
        }
      })
      .catch((err) => {
        console.log('error', err)

        message.error(intl.formatMessage({ id: 'genericError' }))
      })
  }

  onOpenPayDrawer = (dividedBy) => {
    const { checkedItems } = this.state

    this.setState({
      open: true,
      dividedBy,
      totalAmount: getTotal(checkedItems)
    })
  }

  renderCheckedProducts = () => {
    const {
      checkedItems,
      total
    } = this.state
    const disabledPayButton = total === 0

    return (
      <Fragment>
        <header className={s.rowHeader}>
          <h5 className={s.title}>
            <FormattedMessage id="SelectedToPay" />
          </h5>

          <div>
            <FormattedMessage id="selectedCourses" />
            {': '}
            { checkedItems.length || 0 }
          </div>
        </header>
        <div className={s.productsWrapper}>
          <div className={s.checkboxesWrapper}>
            <ul className={s.selectedList}>
              {
                checkedItems.map((product) => (
                  <li className={s.selectedItem} key={uid({ ...product, type: 'checkedproduct' })}>
                    <button
                      className={s.selectedItemButton}
                      onClick={() => { this.onItemSelected(product) }}
                    >
                      <div>
                        {
                          product.quantity || 1
                        }
                        {'x '}
                        {
                          product.name
                        }
                      </div>
                      <div className={s.itemPrice}>
                        <FormattedNumber
                          style="currency"
                          currency={process.env.CURRENCY}
                          value={product.price}
                        />
                      </div>
                    </button>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
        <footer className={s.colFooter}>
          <div className={s.colFooterTopPay}>
            <div className={s.payTotal}>
              <FormattedNumber
                style="currency"
                currency={process.env.CURRENCY}
                value={total || 0}
              />
            </div>
            <Button
              onClick={() => this.onOpenPayDrawer(1)}
              type="primary"
              size="large"
              disabled={disabledPayButton}
            >
              <FormattedMessage id="payAll" />
            </Button>
          </div>

          <div className={s.colFooterTop}>
            <FormattedMessage id="divideBy" />
            <div className={s.dividedByButtons}>
              {
                new Array(8).fill().map((el, index) => (
                  <Button
                    shape="circle"
                    onClick={() => this.onOpenPayDrawer(index + 2)}
                    size="large"
                    disabled={disabledPayButton}
                    key={`button-pay-for-${el}-${index * 2}`}
                  >
                    {index + 2}
                  </Button>
                ))
              }
              
            </div>
          </div>
        </footer>
      </Fragment>
    )
  }

  renderPaidProducts = () => {
    const {
      checkedItems,
    } = this.state

    const {
      productPaid,
    } = this.props

    return (
      <Fragment>
        {
          idx(productPaid, p => p.productList)
          && (
            <div className={s.checkboxesWrapperPaid}>
              {
                productPaid.productList.map((item, index) => (
                  <div className={s.item} key={uid(item)}>
                    <Checkbox
                      value={item.id}
                      disabled
                      checked
                      defaultChecked
                    >
                      <div className={s.checkboxItemContent}>
                        <div>
                          {item.quantity || 1}
                          {'x '}
                          {item.name}
                        </div>
                        <div className={s.itemPrice}>
                          <FormattedNumber
                            style="currency"
                            currency={process.env.CURRENCY}
                            value={item.price}
                          />
                        </div>
                      </div>
                    </Checkbox>
                  </div>
                ))
              }
            </div>
          )
        }
      </Fragment>
    )
  }

  render() {
    const {
      checkedItems,
      indeterminate,
      checkAll,
      productPaying,
      productList,
      open,
      dividedBy,
      totalAmount,
    } = this.state

    const {
      match: {
        params
      },
      loading,
      totalAmount: totalAmountProps,
      productPaid,
      paymentMethods,
      billTableCheckout,
    } = this.props

    const totalAmountToPay = getTotal(productList)
    const checkedValues = checkedItems.map(item => item.unique) 

    return (
      <CheckoutContext.Provider value={{
        paymentMethods,
        createBill: billTableCheckout,
      }}
      >
        <Spin spinning={loading}>
          <div
            id="order-drower-container"
            className={s.wrapper}
          >
          
            <div className={s.inner}>
              <Row gutter={30}>
                <Col className={s.row} span={12}>
                  <header className={s.rowHeader}>
                    <h5 className={s.title}>
                      <FormattedMessage id="orderSummary" />
                    </h5>

                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={this.handleCheckAllChange}
                      checked={checkAll}
                    >
                      <FormattedMessage id="checkAll" />
                    </Checkbox>
                  </header>
                  <div className={s.productsWrapper}>
                    <div className={s.checkboxesWrapper}>
                      <Checkbox.Group value={checkedValues}>
                        { productList.map((product) => (
                          <div className={s.item} key={uid(product)}>
                            <CashItem
                              product={product}
                              onChange={() => this.onItemSelected(product)}
                            />
                          </div>
                        ))
                        }
                      </Checkbox.Group>
                    </div>

                    { this.renderPaidProducts() }
                  </div>
                  
                  <footer className={s.colFooter}>
                    <div className={s.colFooterTop}>
                      <Button onClick={this.handlePrintTableCheckout}>
                        <FormattedMessage id="printPreBill" />
                      </Button>

                      <div className={s.colFooterTotal}>
                        <span className={s.colFooterTotalLabel}>
                          <FormattedMessage id="total" />
                          {': '}
                        </span>
                        <span className={s.colFooterTotalValue}>
                          <FormattedNumber
                            style="currency"
                            currency={process.env.CURRENCY}
                            value={totalAmountProps}
                          />
                        </span>
                      </div>
                    </div>

                    <div className={s.colFooterTop}>

                      <div className={s.colFooterTotal}>
                        <span className={s.colFooterTotalLabel}>
                          <FormattedMessage id="paid" />
                          {': '}
                        </span>
                        <span className={s.colFooterTotalValue}>
                          <FormattedNumber
                            style="currency"
                            currency={process.env.CURRENCY}
                            value={productPaid.totalAmount}
                          />
                        </span>
                      </div>

                      <div className={s.colFooterTotal}>
                        <span className={s.colFooterTotalLabel}>
                          <FormattedMessage id="toPay" />
                          {': '}
                        </span>
                        <span className={s.colFooterTotalValue}>
                          <FormattedNumber
                            style="currency"
                            currency={process.env.CURRENCY}
                            value={totalAmountToPay}
                          />
                        </span>
                      </div>
                    </div>
                  </footer>

                </Col>
                <Col className={s.rowBlack} span={12}>
                  { this.renderCheckedProducts() }
                </Col>
              </Row>
            </div>

            <PayDrawer
              loading={loading}
              onClose={this.closeDrawer}
              visible={open}
              productList={checkedItems}
              dividedBy={dividedBy}
              billList={idx(productPaying, p => p.billList)}
              totalAmount={totalAmount}
              refreshTableCheckout={this.refreshTableCheckout}
            />
          </div>
        </Spin>
      </CheckoutContext.Provider>
    )
  }
}

TableCashDesk.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  billTableCheckout: PropTypes.func.isRequired,
  getTableCheckout: PropTypes.func.isRequired,
  resetTableCheckout: PropTypes.func.isRequired,
  printTableCheckout: PropTypes.func.isRequired,
  getTable: PropTypes.func.isRequired,
  totalAmount: PropTypes.number,
  productPaying: PropTypes.shape(),
  productToPay: PropTypes.shape(),
  productPaid: PropTypes.shape(),
  table: PropTypes.shape().isRequired,
  loading: PropTypes.bool,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({})),
}
TableCashDesk.defaultProps = {
  totalAmount: 0,
  loading: false,
  paymentMethods: [],
  productPaying: {
    productList: [],
    totalAmount: 0,
  },
  productToPay: {
    productList: [],
    totalAmount: 0,
  },
  productPaid: {
    productList: [],
    totalAmount: 0,
  },
}

export default injectIntl(withRouter(withTableCheckout(TableCashDesk)))
