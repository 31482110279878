import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  Button,
  Drawer,
  Icon,
  Radio,
  Divider,
  Input,
} from 'antd'
import {
  NumericRadioInput
} from '..'
import s from './OrderMenuItem.styl'


class OrderMenuItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      opened: false,
      drawerValue: 0
    }
  }

  componentDidMount() {
    this.setState({
      drawerValue: this.props.quantity
    })
  }

  componentDidUpdate(prevProps) {
    const { quantity } = this.props

    if (quantity !== prevProps.quantity) {
      this.updateQuantity(quantity)
    }
  }

  updateQuantity = (quantity) => {
    this.setState({
      drawerValue: quantity
    })
  }


  openDrawer = () => {
    this.setState({
      opened: true,
    })
  }

  closeDrawer = () => {
    this.setState({
      opened: false,
    })
  }

  handleSubmitForm = (e) => {
    const { drawerValue } = this.state

    e.preventDefault()
    const {
      handleChangeQuantity,
    } = this.props
    const { comment } = e.target
    
    this.closeDrawer()

    handleChangeQuantity({
      comment: comment.value,
      quantity: parseInt(drawerValue, 10),
    })
  }

  handleDelete = () => {
    const {
      handleChangeQuantity,
    } = this.props

    handleChangeQuantity({
      comment: '',
      quantity: 0,
    })
    this.closeDrawer()
  }

  showCommneticon = () => {
    const {
      quantity,
      comment,
    } = this.props

    if (quantity > 0 && comment) {
      return (
        <div
          className={s.commentBadge}
        >
          <Icon type="form" />
        </div>
      )
    }

    return false
  }

  showRemoveButton = () => {
    const {
      quantity,
    } = this.props

    if (quantity > 0) {
      return (
        <Button
          className={s.closeButton}
          shape="circle"
          icon="close"
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            this.handleDelete()
          }}
        />
      )
    }

    return false
  }

  showCommnetIcon = () => {
    const {
      quantity,
      comment,
    } = this.props

    if (quantity > 0 && comment) {
      return (
        <span
          className={s.commentPreview}
        >
          {' — '}
          {comment}
        </span>
      )
    }

    return false
  }

  showCounterButtons = () => {
    const {
      quantity,
      handleChangeQuantity,
    } = this.props

    if (quantity > 0) {
      return (
        <div className={s.footer}>
          <button
            onClick={(e) => {
              e.stopPropagation()

              handleChangeQuantity({
                quantity: quantity - 1
              })
            }}
            className={s.counterButton}
          >
            <Icon type="minus" />
          </button>

          <div className={s.counterValue}>{quantity}</div>
          
          <button
            onClick={(e) => {
              e.stopPropagation()

              handleChangeQuantity({
                quantity: quantity + 1
              })
            }}
            className={s.counterButton}
          >
            <Icon type="plus" />
          </button>
        </div>
      )
    }

    return false
  }

  onChangeValueInDrawer = (newValue) => {
    this.setState({
      drawerValue: newValue,
    })
  }

  render() {
    const { opened, drawerValue } = this.state
    const {
      title,
      quantity,
      comment,
      handleChangeQuantity,
    } = this.props

    return (
      <div>
        <div
          className={quantity > 0 ? s.wrapperFill : s.wrapper}
          onClick={() => {
            if (quantity > 0) {
              this.openDrawer()
            } else {
              handleChangeQuantity({
                quantity: 1
              })
            }
          }}
          role="button"
          onKeyDown={() => {}}
          tabIndex="0"
        >
          
          { this.showRemoveButton() }
          <span>{title}</span>
          { this.showCommnetIcon() }
          { this.showCounterButtons() }
          
        </div>
        <Drawer
          title={title}
          visible={opened}
          destroyOnClose
          maskClosable={false}
          onClose={this.closeDrawer}
          width={500}
        >
          <form onSubmit={this.handleSubmitForm}>
            <h3 className={s.title}>
              <FormattedMessage id="plateQuantity" />
            </h3>
            <NumericRadioInput
              maxValue={7}
              value={drawerValue}
              onChange={this.onChangeValueInDrawer}
            />
            <div className={s.spacer} />
            <Input.TextArea autosize={{ minRows: 3, maxRows: 8 }} name="comment" defaultValue={comment} />
            <Divider />
            <footer className={s.formFooter}>
              <button
                className={s.buttonDelete}
                type="button"
                onClick={this.handleDelete}
              >
                <FormattedMessage id="deleteFromOrder" />
              </button>

              <Button htmlType="submit" type="primary">
                <FormattedMessage id="save" />
              </Button>
            </footer>
          </form>
        </Drawer>
      </div>
    )
  }
}

OrderMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  comment: PropTypes.string,
  handleChangeQuantity: PropTypes.func.isRequired,
}
OrderMenuItem.defaultProps = {
  quantity: 0,
  comment: null,
}

export default OrderMenuItem
