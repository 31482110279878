import React, { Component } from 'react'
import { uid } from 'react-uid'
import PropTypes from 'prop-types'
import idx from 'idx'
import {
  FormattedMessage,
  FormattedNumber,
  injectIntl
} from 'react-intl'
import {
  Link,
  withRouter,
} from 'react-router-dom'
import {
  Layout,
  Icon,
  Menu,
  Button,
  Drawer,
} from 'antd'
import s from './PayDrawer.styl'
import {
  PayOut
} from '..'

class PayDrawer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      key: 0,
      amounts: [],
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { billList } = props

    return {
      ...state,
      key: billList.length
    }
  }

  componentDidMount(prevProps) {
    this.setAmounts()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.billList !== prevProps.billList
      || this.props.visible !== prevProps.visible
    ) {
      this.setAmounts()
    }
  }

  setAmounts = () => {
    const amounts = this.getAmountsFromBillList()

    this.setState({
      amounts
    })
  }

  getAmountsFromBillList = (index, value) => {
    const { dividedBy, billList, totalAmount } = this.props

    return new Array(dividedBy).fill().map((e, i) => {
      if (billList[i]) return Number(billList[i].paidTotal)

      if (index === i && value) return Number(value) 

      //eslint-disable-next-line
      if (value) return Number((totalAmount - value - this.getPaidInThisPaymentAmount()) / (this.getRestPeopleNumberToPay() - 1))
      
      //eslint-disable-next-line
      const amount = Number((totalAmount - this.getPaidInThisPaymentAmount()) / (this.getRestPeopleNumberToPay()))

      return amount.toFixed(2)
    })
  }

  getPaidInThisPaymentAmount = () => {
    const { billList } = this.props

    return billList.reduce((accumulator, bill) => accumulator + bill.paidTotal, 0)
  }

  getRestPeopleNumberToPay = () => {
    const { dividedBy, billList } = this.props

    return dividedBy - billList.length
  }

  handleChangeValue = ({ index, value }) => {
    this.setState({
      amounts: this.getAmountsFromBillList(index, value),
    })
  }

  render() {
    const {
      key,
      amounts
    } = this.state

    const {
      visible,
      totalAmount,
      dividedBy,
      productList,
      loading,
      billList,
      onClose,
      refreshTableCheckout,
      match: {
        params: {
          tableId,
        }
      }
    } = this.props

    return (
      <div>
        <Drawer
          width="80%"
          visible={visible}
          maskClosable={false}
          closable={idx(billList, b => !b.length > 0 && visible)}
          className={s.drawer}
          onClose={onClose}
        >
          <Layout
            className={s.mainWrapper}
          >
            <Layout.Sider className={s.sider}>
              <div className={s.header}>
                <FormattedMessage id="payment" />
              </div>
              <Menu
                selectedKeys={[`${key}`]}
                mode="inline"
              >
                {
                  new Array(dividedBy).fill().map((el, index) => (
                    <Menu.Item key={`${index * 1}`} disabled={key > index}>
                      <FormattedMessage
                        id="bill"
                        values={{
                          number: index + 1
                        }}
                      />
                      {': '}
                      <small>
                        <FormattedNumber
                          style="currency"
                          currency={process.env.CURRENCY}
                          value={amounts[index] || 0}
                        />
                      </small>
                    </Menu.Item>
                  ))
                }
              </Menu>

              <div className={s.sideTotal}>
                <FormattedMessage id="total" />
                {': '}
                <FormattedNumber
                  style="currency"
                  currency={process.env.CURRENCY}
                  value={totalAmount}
                />
              </div>
              
              <div className={s.returnToOrdersWrapper}>
                <Link to={`/tables/${tableId}/orders`}>
                  <Button type="default" block>
                    <Icon type="left" />
                    <FormattedMessage id="returnToOrders" />
                  </Button>
                </Link>
              </div>
            </Layout.Sider>
            <Layout.Content className={s.content}>
              { 
                amounts
                && amounts.length > 0
                && new Array(dividedBy).fill().map((el, index) => (
                  <div
                    key={uid({ type: 'account_divided_by', index })}
                    style={{
                      display: key === index ? 'block' : 'none',
                    }}
                  >
                    <PayOut
                      loading={loading}
                      index={index}
                      productList={productList}
                      dividedBy={dividedBy}
                      billLength={billList.length}
                      refreshTableCheckout={refreshTableCheckout}
                      maxAmountToPay={totalAmount - this.getPaidInThisPaymentAmount()}
                      handleChangeValue={(value) => this.handleChangeValue({
                        value,
                        index,
                      })}
                      
                      isLast={dividedBy === (index + 1)}
                      toPay={Number(amounts[index])}
                    />
                  </div>
                ))
              }
            </Layout.Content>
          </Layout>
        </Drawer>
      </div>
    )
  }
}

PayDrawer.propTypes = {
  match: PropTypes.shape({}).isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  totalAmount: PropTypes.number,
  refreshTableCheckout: PropTypes.func.isRequired,
  dividedBy: PropTypes.number,
  loading: PropTypes.bool,
  billList: PropTypes.arrayOf(PropTypes.shape()),
  productList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}
PayDrawer.defaultProps = {
  visible: false,
  loading: false,
  totalAmount: 0,
  dividedBy: 1,
  billList: [],
  onClose: () => {},
}

export default injectIntl(withRouter(PayDrawer))
