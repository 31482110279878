import { combineReducers } from 'redux'
import { foodOrders } from './FoodOrders'
import { beverageOrders } from './BeverageOrders'
import { locale } from './Locale'
import { loading } from './Loading'
import { tableAreas } from './TableAreas'
import { tables } from './Tables'
import { table } from './Table'
import { user, allergens } from './Options'
import { foodAreas, foods } from './FoodAreas'
import { beverageAreas, beverages } from './BeverageAreas'
import { checkout } from './Checkout'
import { paymentMethods } from './PaymentMethods'
import { bookings } from './Bookings'

const rootReducer = combineReducers({
  locale,
  tableAreas,
  loading,
  tables,
  table,
  foodAreas,
  foodOrders,
  foods,
  beverageAreas,
  beverages,
  beverageOrders,
  user,
  checkout,
  paymentMethods,
  allergens,
  bookings,
})

export default rootReducer
