import UrlPattern from 'url-pattern'

const up = p => new UrlPattern(p)

const apiPatterns = {
  options: '/options/',
  allTables: '/tables/',
  tableInfo: '/tables/:tableId',
  tableUpdate: '/tables/:tableId',
  tableTake: '/tables/:tableId/take',
  tableUse: '/tables/:tableId/use',
  tableFree: '/tables/:tableId/free',
  tableMove: '/tables/:tableId/move',
  tableSetClient: '/tables/:tableId/client',
  tableRemoveClient: '/tables/:tableId/client/remove',
  allFoods: '/food/',
  foodsAreas: '/foods/areas',
  allBeverages: '/beverages/',
  beveragesAreas: '/beverages/areas',
  clients: '/clients/',
  client: '/clients/(:clientId)',
  clientCreate: '/clients/',
  createFoodOrder: '/tables/:tableId/food-orders/',
  createBeverageOrder: '/tables/:tableId/beverage-orders/',
  goCourse: '/courses/:courseId/go',
  tableCheckout: '/tables/:tableId/checkout/',
  tablePrintCheckout: '/tables/:tableId/checkout/print',
  tableBillsCheckout: '/tables/:tableId/checkout/bills/',
  paymentMethods: '/payment_methods/',
  allBookings: '/booking/',
  singleBooking: '/booking/:bookingId',
  bookingSettable: '/booking/:bookingId/settable',

  getToken: '/token',
  validateToken: '/token/validate',
}

export const getApiEndpoint = (type, params = {}) => {
  let host = process.env.API
  const api = up(apiPatterns[type]).stringify(params)

  if (type === 'getToken' || type === 'validateToken') {
    host = process.env.APIAUTH
  }

  return `${host}${api}`
}
