import React, { Component } from 'react'
import PropTypes from 'prop-types'
import idx from 'idx'
import {
  Radio,
} from 'antd'
import s from './NumericRadioInput.styl'

class NumericRadioInput extends Component {
  onValueChange = (e) => {
    const { onChange } = this.props
    const value = idx(e, (_) => _.target.value) || 0;

    onChange(value)
  }

  render() {
    const { maxValue, value } = this.props

    return (
      <div>
        <Radio.Group
          size="large"
          name="quantity"
          value={value}
          onChange={this.onValueChange}
          className={s.numberRadio}
        >
          {
            Array(maxValue || 7)
              .fill()
              .map((el, i) => (
                <Radio.Button
                  key={`${i + 1}-radio`}
                  value={i + 1}
                >
                  {i + 1}
                </Radio.Button>
              ))
          }
        </Radio.Group>
      </div>
    )
  }
}

NumericRadioInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  maxValue: PropTypes.number,
  value: PropTypes.number,
}
NumericRadioInput.defaultProps = {
  maxValue: 10,
  value: 0,
}

export default NumericRadioInput
