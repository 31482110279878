const CHANGE_LOCALE = 'CHANGE_LOCALE'

export const changeLocale = (locale) => (
  {
    type: CHANGE_LOCALE,
    locale
  }
)

export const locale = (state = 'it', action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return action.locale
    default:
      return state
  }
}
