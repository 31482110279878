import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import s from './Table.styl'
import { ReactComponent as UsedIcon } from './in-use.svg'
import { ReactComponent as RiservedIcon } from './reserved-dark.svg'
import { ReactComponent as BusyIcon } from './busy.svg'

const used = (status) => status === 'in-use'

const getScaleValueFormSize = (tablesSize) => {
  switch (tablesSize) {
    case 'xsmall':
      return 0.7
    case 'small':
      return 0.8
    case 'large':
      return 1
    case 'xlarge':
      return 1.2
    default:
      return 0.9
  }
}

const Table = ({
  size,
  table: {
    x,
    y,
    name,
    status,
    usedSeats,
    id,
    bookings,
    subTable,
    shortName,
    owner,
  }
}) => {
  const getIcon = () => {
    if (!used(status)) {
      return null
    }
    
    return (
      <span className={s.statusIcon}>
        {
          owner.short === 'me'
            ? <UsedIcon />
            : <BusyIcon />
        }
      </span>
    )
  }

  const getTitleClass = () => {
    if (!used(status)) {
      return s.number
    }

    if (owner.short === 'me') {
      return s.numberUsed
    }

    return s.numberBusy
  }

  const getStatusValue = () => {
    if (!used(status)) {
      return (
        <FormattedMessage id="free" />
      )
    }

    return (
      <span className={owner.short === 'me' ? s.statusUsed : s.statusBusy}>
        <FormattedMessage id="copertiUsati" values={{ usedSeats }} />
      </span>
    )
  }
  
  return (
    <Link to={`/tables/${id}`}>
      <div
        className={subTable ? s.tableSmall : s.table}
        style={{
          left: `${x}%`,
          top: `${y}%`,
          transform: `scale(${getScaleValueFormSize(size)})`, 
        }}
      >
        {
          name
          && (
            <span className={getTitleClass()}>
              {subTable ? shortName : name }
            </span>
          )
        }

        <div className={s.icons}>
          {
            getIcon()
          }

          {
            bookings && bookings.length > 0
            && (
              <span className={s.reservedIcon}>
                <RiservedIcon />
              </span>
            )
          }
        </div>
      
        <span className={s.status}>
          <div className={s.statusLabel}>
            <FormattedMessage id="status" />
            :
          </div>

          { getStatusValue()}
        </span>
      </div>
    </Link>
  )
}

Table.propTypes = {
  table: PropTypes.shape({}).isRequired,
  size: PropTypes.string,
}
Table.defaultProps = {
  size: 'medium'
}

export default Table
