const it = {
  orderAndTables: 'Ordinazioni e tavoli',
  view_mess: 'Visualizza messaggio',
  status: 'Stato',
  user: 'Utente',
  free: 'Libero',
  'in-use': 'In uso',
  reserved: 'Riservato',
  copertiUsati: '{usedSeats} Coperti',
  hour: 'Ora',
  date: 'Data',
  order: 'Comanda',
  preBill: 'Preconto',
  cashOut: 'Cassa',
  tableStatus: 'Stato del Tavolo',
  numOfSeats: 'Numero di coperti',
  food: 'Cibo',
  beverage: 'Bevande',
  addOrder: 'Aggiungi ordine',
  incomingHour: 'Ora di arrivo:',
  bookingList: 'Elenco prenotazioni',
  digitCodeToAccess: 'Digita il tuo codice o appoggia la tessera sul lettor per accedere',
  cancel: 'Annulla',
  exitMenuItem: '{number} Uscita',
  save: 'Salva',
  plateQuantity: 'Numero di piatti',
  deleteFromOrder: 'Elimina dall\'ordine',
  deleteOrder: 'Elimina ordine',
  saveOrder: 'Salva Ordine',
  sendOrder: 'Invia Ordine',
  next: 'Segue',
  sendToKitchen: 'Inviato in cucina alle: {time}',
  go: 'Via',
  addCourse: 'Aggiungi Uscita',
  edit: 'Modifica',
  printOrder: 'Stampa Ordine',
  table: 'Tavolo',
  moveTable: 'Sposta tavolo',
  selectTable: 'Seleziona tavolo',
  movingTableText: 'Stai per spostare un tavolo con dellecomande attive',
  movingTableSelectDestination: 'Seleziona un tavolo di destinazione',
  ErrorOrderWithoutQuantity: 'Attenzione! Non è possibile creare un ordine vuoto.',
  loadingOrder: 'Invio ordine in corso...',
  clientArrived: 'Cliente arrivato',
  selectClient: 'Seleziona cliente',
  addNewClient: 'Crea nuovo cliente',
  otherwhise: 'oppure',
  createNewClient: 'Crea nuovo cliente',
  name: 'Nome',
  surname: 'Cognome',
  email: 'Email',
  phone: 'Tel/Cel',
  seats: 'Coperti',
  insertClientNote: 'Inserisci note (es. intolleranze, diete, ecc...)',
  infoClient: 'Info cliente',
  selectAllergens: 'Seleziona allergie',
  client: 'Cliente',
  agreement: 'Convenzione',
  company: 'Azienda',
  VATNumber: 'P.IVA',
  updateClient: 'Aggiorna Cliente',
  cancelAndClose: 'Annulla e chiudi',
  nameInputValidation: 'Il nome del cliente è necessario',
  surnameInputValidation: 'Il cognome del cliente è necessario',
  emailInputFormatValidation: 'L\'email inserita non è valida',
  emailInputValidation: 'L\'email del cliente è necessaria',
  error: 'Errore',
  printSuccessfull: 'La stampa è stata effettuata',
  genericError: 'Qualcosa è andato storto',
  clientCreationSuccessMsg: 'Cliente creato con successo',
  clientUpdatingSuccessMsg: 'Cliente aggiornato con successo',
  tableLockedMsg: 'Questo tavolo è in gestione a {user},',
  tableLockedTakeOwnershipMsg: 'vuoi prenderlo in gestione tu?',
  takeControl: 'Prendi il controllo',
  orderSummary: 'Riepilogo dell\'ordine',
  SelectedToPay: 'Selezionati da pagare',
  checkAll: 'Seleziona tutto',
  printPreBill: 'Stampa preconto',
  total: 'Totale',
  toPay: 'Da pagare',
  paid: 'Pagato',
  selectedCourses: 'Piatti selezionati',
  payAll: 'Paga tutto',
  divideBy: 'Dividi per:',
  payment: 'Pagamento',
  bill: 'Conto {number}',
  paymentMethod: 'Metodo di pagamento',
  inPayment: 'In pagamento',
  cash: 'Contanti',
  creditCard: 'Carta di credito',
  paypal: 'Paypal',
  tablesAndOrders: 'Tavoli e Comande',
  tablesPage: 'Tavoli',
  reservationPage: 'Prenotazioni',
  editAmount: 'Modifica importo',
  amountIsTooHight: 'L\'importo è troppo alto',
  amountIsTooLow: 'L\'importo è troppo basso',
  paydBy: 'Pagato in',
  receipt: 'Scontrino Fiscale',
  electronicInvoice: 'Fattura Elettronica',
  goToMovedTable: 'Vai al tavolo',
  doYouWantToGoToMovedTable: 'Vuoi visualizzare il tavolo di destinazione?',
  returnToOrders: 'Torna agli ordini',
  editBooking: 'Modifica prenotazione',
  addBooking: 'Aggiungi nuova prenotazione',
  errorOnLogin: 'Il codice inserito non è corretto!',
}

module.exports = it
