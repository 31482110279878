import { connect } from 'react-redux'
import { getOptions } from '../data/Options'

const mapStateToProps = (state) => ({
  allergens: state.allergens,
  loading: state.loading.options,
})

const mapDispatchToProps = (dispatch) => ({
  getOptions: () => dispatch(getOptions()),
})

const withAllergens = component => connect(mapStateToProps, mapDispatchToProps)(component)

export default withAllergens
