import { RSAA } from 'redux-api-middleware'
import idx from 'idx'
import { getApiEndpoint } from '../utils/ApiUrls'
import { manageResponseFetch, getDefaultHeaders } from '../utils/Functions'

export const GET_BOOKINGS_REQUEST = 'tables/GET_BOOKINGS_REQUEST'
export const GET_BOOKINGS_SUCCEDED = 'tables/GET_BOOKINGS_SUCCEDED'
export const GET_BOOKINGS_FAILED = 'tables/GET_BOOKINGS_FAILED'

export const getBookings = () => {
  const endpoint = getApiEndpoint('allBookings')

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'GET',
      headers: {
        ...getDefaultHeaders()
      },
      types: [
        GET_BOOKINGS_REQUEST,
        {
          type: GET_BOOKINGS_SUCCEDED,
          payload: manageResponseFetch
        },
        GET_BOOKINGS_FAILED
      ]
    }
  }
}


export const bookings = (state = [], action) => {
  switch (action.type) {
    case GET_BOOKINGS_REQUEST:
      return [
        ...state,
      ]
    case GET_BOOKINGS_SUCCEDED:
      return [
        ...action.payload
      ]
    case GET_BOOKINGS_FAILED:
      return []
    default:
      return state
  }
}
