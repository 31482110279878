import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
} from 'antd'
import s from './ButtonPlaceholder.styl'


const ButtonPlaceholder = ({ children, ...rest }) => (
  <Button
    {...rest}
    type="dashed"
    className={s.button}
    block
    size="large"
  >
    {children}
  </Button>
)

ButtonPlaceholder.propTypes = {
  children: PropTypes.shape({}),
}
ButtonPlaceholder.defaultProps = {
  children: null
}

export default ButtonPlaceholder
