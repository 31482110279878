import { RSAA } from 'redux-api-middleware'
import idx from 'idx'
import { getApiEndpoint } from '../utils/ApiUrls'
import { manageResponseFetch, getDefaultHeaders } from '../utils/Functions'

export const GET_TABLES_REQUEST = 'tables/GET_TABLES_REQUEST'
export const GET_TABLES_SUCCEDED = 'tables/GET_TABLES_SUCCEDED'
export const GET_TABLES_FAILED = 'tables/GET_TABLES_FAILED'

export const getTables = () => {
  const endpoint = getApiEndpoint('allTables')

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
      types: [
        GET_TABLES_REQUEST,
        {
          type: GET_TABLES_SUCCEDED,
          payload: manageResponseFetch
        },
        GET_TABLES_FAILED
      ]
    }
  }
}


export const tables = (state = [], action) => {
  switch (action.type) {
    case GET_TABLES_REQUEST:
      return [
        ...state,
      ]
    case GET_TABLES_SUCCEDED:
      return [
        ...action.payload
      ]
    case GET_TABLES_FAILED:
      return []
    default:
      return state
  }
}
