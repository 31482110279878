import React, { Component } from 'react'
import {
  Drawer,
  Icon,
  Layout,
  Menu,
  Button,
  Tabs,
  Badge
} from 'antd'
import find from 'lodash.find'
import PropTypes from 'prop-types'
import idx from 'idx'
import { FormattedMessage } from 'react-intl'
import { uid } from 'react-uid';
import { OrderMenuItem } from '..'
import { cleanCoursesByEmpty } from '../../utils/Functions'
import s from './NewBeverageOrderDrawer.styl'

const normalizeInitialState = (initialState) => {
  if (!initialState) {
    return [
      {}
    ]
  }

  const newCourses = initialState.courses.map((c) => c.beverageList)

  return [
    ...newCourses
  ]
}

const countCoursePlats = (course) => {
  let count = 0
  Object.keys(course).forEach(key => {
    const { quantity } = course[key];
    count += quantity
  })

  return count
}

const findBeverageByID = (beverageId, allBeverages) => (
  find(allBeverages, (beverage) => beverage.id === beverageId)
)

const countBeverageInArea = (course, allBeverages) => {
  let count = 0
  Object.keys(course).map((k, i) => {
    if (findBeverageByID(k, allBeverages)) {
      count += course[k].quantity
    }
  })

  return count
}

const getAreaBeverages = (area) => {
  const beverages = idx(area, (a) => a.beverages) ? [...area.beverages] : []

  if (idx(area, (_) => _.childs && _.childs.length > 0)) {
    idx(area, (_) => _.childs.map((a) => {
      beverages.push(...a.beverages)
    }))
  }

  return beverages
}

class NewBeverageOrderDrawer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      key: 0,
      childrenIndex: 0,
      courses: [
        {}
      ],
    }
  }

  componentDidUpdate(oldProps) {
    const { visible } = this.props
    
    if (visible && !oldProps.visible) {
      this.resetState()
    }
  }

  handleClick = ({ item, key, keyPath }) => {
    this.setState({
      key: parseInt(key, 10)
    })   
  }

  handleConfirmOrder = () => {
    const { courses } = this.state
    const { handleConfirmOrder, initialState } = this.props

    handleConfirmOrder({
      courses: cleanCoursesByEmpty(courses),
      orderId: idx(initialState, (_) => _.id) || null,
    })
  }

  onTabsChange = () => {
    this.setState({
      childrenIndex: 0,
    })
  }

  handleChangeQuantity = (courseIndex, id, v) => {
    const { courses } = this.state
    const newCourse = [...courses]

    if (idx(newCourse, _ => _[courseIndex][id])) {
      if (v.quantity === 0) {
        delete newCourse[courseIndex][id]
      } else if (v.quantity !== undefined) {
        newCourse[courseIndex][id].quantity = v.quantity
        if (v.comment) newCourse[courseIndex][id].comment = v.comment
      }
    } else {
      newCourse[courseIndex][id] = {
        quantity: parseInt(v.quantity, 10),
        comment: v.comment
      }
    }

    this.setState({
      courses: newCourse
    })
  }

  resetState = () => {
    const { initialState } = this.props

    this.setState({
      key: 0,
      courses: initialState ? normalizeInitialState(initialState) : [
        {}
      ],
    })
  }

  getBeverageName = (beverageId) => {
    const {
      beverages,
    } = this.props

    return find(beverages, (beverage) => beverage.id === beverageId)
  }

  changeChildrenIndex = (childrenIndex) => {
    this.setState({
      childrenIndex
    })
  }

  renderBeverageItems = (items, area, course, courseIndex) => (
    <div className={s.orderItemsWrapper} key={uid({ ...items, ...courseIndex })}>
      { items.map((b) => (
        <div
          className={s.item}
          key={uid(b)}
        >
          <OrderMenuItem
            id={b.id}
            title={b.name}
            area={area.name}
            quantity={course[b.id] ? course[b.id].quantity : 0}
            comment={course[b.id] ? course[b.id].comment : null}
            handleChangeQuantity={(v) => {
              this.handleChangeQuantity(courseIndex, b.id, v)
            }}
          />
        </div>
      ))
      }
    </div>
  )

  renderChildrenButtons = (childs) => {
    const { childrenIndex } = this.state

    return childs.map((child, index) => (
      <Button
        type={childrenIndex === index ? 'primary' : 'ghost'}
        shape="round"
        size="small"
        onClick={() => { this.changeChildrenIndex(index) }}
        key={uid(child)}
      >
        {child.name}
      </Button>
    ))
  }

  render() {
    const { key, courses, childrenIndex } = this.state
    const {
      visible,
      handleCloseDrawer,
      beverageAreas
    } = this.props

    return (
      <Drawer
        placement="right"
        destroyOnClose
        closable={false}
        maskClosable={false}
        maskStyle={{
          left: 0,
        }}
        // zIndex={999}
        width="80%"
        onClose={handleCloseDrawer}
        visible={visible}
        className={s.drawer}
      >
        <Layout
          className={s.mainWrapper}
        >
          <Layout.Sider theme="light" className={s.sider}>
            <h3 className={s.menuTitle}><FormattedMessage id="order" /></h3>
            <Menu
              onClick={this.handleClick}
              selectedKeys={[`${key}`]}
              mode="inline"
            >
              {
                courses.map((course, index) => (
                  <Menu.Item key={`${index * 1}`}>
                    <FormattedMessage
                      id="exitMenuItem"
                      values={{
                        number: ''
                      }}
                    />
                    <Badge
                      style={{ backgroundColor: '#999', color: '#fff', marginLeft: 10 }}
                      count={countCoursePlats(course)}
                    />

                    {
                      Object.keys(course).map((k) => {
                        const beverage = this.getBeverageName(k)

                        if (course[k].quantity === 0) {
                          return (null)
                        }
                        
                        return (
                          <div className={s.menuPlate} key={uid(course[k])}>
                            {`${course[k].quantity}x `}
                            {beverage.name}
                          </div>
                        )
                      })
                    }
                  </Menu.Item>
                ))
              }
            </Menu>
          </Layout.Sider>
          <Layout.Content className={s.content}>
            {
              courses.map((course, courseIndex) => (
                <Tabs
                  type="card"
                  onChange={this.onTabsChange}
                  style={{
                    display: courseIndex === key ? 'block' : 'none' 
                  }}
                  key={uid(`course-${courseIndex}`)}
                >
                  {
                    beverageAreas.map((area, i) => (
                      <Tabs.TabPane
                        tab={
                          <Badge
                            className={s.tabBadge}
                            count={countBeverageInArea(course, getAreaBeverages(area))}
                          >
                            <span className={s.tabTitle}>
                              {area.name}
                            </span>
                          </Badge>
                        }
                        key={uid(`beverages-area-${area.id}`)}
                      >
                        <div className={s.childrenButtons}>
                          {
                            area.childs
                            && area.childs.length > 0
                              ? this.renderChildrenButtons(area.childs)
                              : this.renderBeverageItems(
                                area.beverages,
                                area,
                                course,
                                courseIndex
                              )
                          }
                        </div>
                        {
                          area.childs
                          && area.childs.length > 0
                          && area.childs.map((child, index) => (
                            childrenIndex === index
                            && this.renderBeverageItems(
                              child.beverages,
                              child,
                              course,
                              courseIndex
                            )
                          ))
                        }
                      </Tabs.TabPane>
                    ))
                  }
                </Tabs>
              ))
            }
          </Layout.Content>
          <div className={s.footer}>
            <button
              onClick={handleCloseDrawer}
              className={s.buttonDelete}
            >
              <FormattedMessage id="deleteOrder" />
            </button>

            <Button
              className={s.button}
              onClick={this.handleConfirmOrder}
            >
              <FormattedMessage id="sendOrder" />
            </Button>
            <div />
          </div>
        </Layout>
      </Drawer>
    )
  }
}

NewBeverageOrderDrawer.propTypes = {
  handleConfirmOrder: PropTypes.func.isRequired,
  handleCloseDrawer: PropTypes.func.isRequired,
  beverageAreas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  beverages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  visible: PropTypes.bool,
  initialState: PropTypes.shape({}),
}
NewBeverageOrderDrawer.defaultProps = {
  visible: false,
  initialState: null,
}

export default NewBeverageOrderDrawer
