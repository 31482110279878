import React, { Component } from 'react';
import ReduxThunk from 'redux-thunk'
import PropTypes from 'prop-types'
import { createStore, applyMiddleware, compose } from 'redux'
import {
  BrowserRouter as Router,
  withRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { apiMiddleware } from 'redux-api-middleware'
import { Provider } from 'react-redux'
import { addLocaleData } from 'react-intl'
import it from 'react-intl/locale-data/it'
import { Routes } from '../containers'
import { LoginPage } from '../pages'
import reducer from '../data'
import IntlProvider from './intlRedux'

addLocaleData([...it ])

const composeEnhancers = typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose

const enhancer = composeEnhancers(
  applyMiddleware(ReduxThunk, apiMiddleware),
)

const store = createStore(reducer, enhancer)

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

ScrollToTop.propTypes = {
  location: PropTypes.shape({}).isRequired,
  children: PropTypes.shape({}).isRequired,
}

const ScrollToTopComponent = withRouter(ScrollToTop)

/* eslint-disable */
const PrivateRoute = ({
  component: Comp,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      window.EGToken ? (
        <Comp {...props}/>
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: {
            referrer: props.location.pathname,
          }
        }}/>
      )
    )}
  />
)
/* enable-disable */

const App = () => (
  <Provider store={store}>
    <IntlProvider>
      <Router>
        <ScrollToTopComponent>
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <PrivateRoute component={Routes} />
          </Switch>
        </ScrollToTopComponent>
      </Router>
    </IntlProvider>
  </Provider>
)

export default App;
