import {
  GET_TABLE_AREAS_REQUEST,
  GET_TABLE_AREAS_SUCCEDED,
  GET_TABLE_AREAS_FAILED,
} from './TableAreas'
import {
  GET_TABLES_REQUEST,
  GET_TABLES_SUCCEDED,
  GET_TABLES_FAILED,
} from './Tables'
import {
  GET_BOOKINGS_REQUEST,
  GET_BOOKINGS_SUCCEDED,
  GET_BOOKINGS_FAILED,
} from './Bookings'
import {
  GET_CHECKOUT_REQUEST,
  GET_CHECKOUT_SUCCEDED,
  GET_CHECKOUT_FAILED,
} from './Checkout'
import {
  GET_TABLE_REQUEST,
  GET_TABLE_SUCCEDED,
  GET_TABLE_FAILED,
  UPDATE_TABLE_REQUEST,
  UPDATE_TABLE_SUCCEDED,
  UPDATE_TABLE_FAILED,
  FREE_TABLE_REQUEST,
  FREE_TABLE_SUCCEDED,
  FREE_TABLE_FAILED,
  USE_TABLE_REQUEST,
  USE_TABLE_SUCCEDED,
  USE_TABLE_FAILED,
  SET_CLIENT_TABLE_REQUEST,
  SET_CLIENT_TABLE_SUCCEDED,
  SET_CLIENT_TABLE_FAILED,
  REMOVE_CLIENT_TABLE_REQUEST,
  REMOVE_CLIENT_TABLE_SUCCEDED,
  REMOVE_CLIENT_TABLE_FAILED,
  TAKE_TABLE_REQUEST,
  TAKE_TABLE_SUCCEDED,
  TAKE_TABLE_FAILED,
} from './Table'
import {
  GET_OPTIONS_REQUEST,
  GET_OPTIONS_SUCCEDED,
  GET_OPTIONS_FAILED,
} from './Options'
import {
  GET_TOKEN_REQUEST,
  GET_TOKEN_SUCCEDED,
  GET_TOKEN_FAILED,
} from './Auth'

export const loading = (state = {
  areas: false,
  tables: false,
  table: false,
  options: false,
  updatingTable: false,
  tableClient: false,
  checkoutTable: false,
  login: false,
}, action) => {
  switch (action.type) {
    case GET_TABLE_AREAS_REQUEST:
      return {
        ...state,
        areas: true,
      }
    case GET_TABLE_AREAS_SUCCEDED:
    case GET_TABLE_AREAS_FAILED:
      return {
        ...state,
        areas: false,
      }
    case SET_CLIENT_TABLE_SUCCEDED:
    case REMOVE_CLIENT_TABLE_SUCCEDED:
    case SET_CLIENT_TABLE_FAILED:
    case REMOVE_CLIENT_TABLE_FAILED:
      return {
        ...state,
        tableClient: false,
      }
    case SET_CLIENT_TABLE_REQUEST:
    case REMOVE_CLIENT_TABLE_REQUEST:
      return {
        ...state,
        tableClient: true,
      }
    case GET_TABLES_REQUEST:
      return {
        ...state,
        tables: true,
      }
    case GET_TABLES_SUCCEDED:
    case GET_TABLES_FAILED:
      return {
        ...state,
        tables: false,
      }
    case GET_BOOKINGS_REQUEST:
      return {
        ...state,
        bookings: true,
      }
    case GET_BOOKINGS_SUCCEDED:
    case GET_BOOKINGS_FAILED:
      return {
        ...state,
        bookings: false,
      }
    case GET_TABLE_REQUEST:
    case FREE_TABLE_REQUEST:
    case USE_TABLE_REQUEST:
    case TAKE_TABLE_REQUEST:
      return {
        ...state,
        table: true,
      }
    case GET_TABLE_SUCCEDED:
    case GET_TABLE_FAILED:
    case FREE_TABLE_SUCCEDED:
    case FREE_TABLE_FAILED:
    case USE_TABLE_SUCCEDED:
    case USE_TABLE_FAILED:
    case TAKE_TABLE_SUCCEDED:
    case TAKE_TABLE_FAILED:
      return {
        ...state,
        table: false,
      }
    case GET_OPTIONS_REQUEST:
      return {
        ...state,
        options: true,
      }
    case GET_OPTIONS_SUCCEDED:
    case GET_OPTIONS_FAILED:
      return {
        ...state,
        options: false,
      }
    case UPDATE_TABLE_REQUEST:
      return {
        ...state,
        updatingTable: true,
      }
    case UPDATE_TABLE_SUCCEDED:
    case UPDATE_TABLE_FAILED:
      return {
        ...state,
        updatingTable: false,
      }
    case GET_CHECKOUT_REQUEST:
      return {
        ...state,
        checkoutTable: true,
      }
    case GET_CHECKOUT_SUCCEDED:
    case GET_CHECKOUT_FAILED:
      return {
        ...state,
        checkoutTable: false,
      }
    case GET_TOKEN_REQUEST:
      return {
        ...state,
        login: true,
      }
    case GET_TOKEN_SUCCEDED:
    case GET_TOKEN_FAILED:
      return {
        ...state,
        login: false,
      }
    default:
      return state
  }
}
