import { RSAA } from 'redux-api-middleware'
import { getApiEndpoint } from '../utils/ApiUrls'
import { manageResponseFetch, getDeviceIdFromCookie } from '../utils/Functions'

export const GET_TOKEN_REQUEST = 'auth/GET_TOKEN_REQUEST'
export const GET_TOKEN_SUCCEDED = 'auth/GET_TOKEN_SUCCEDED'
export const GET_TOKEN_FAILED = 'auth/GET_TOKEN_FAILED'

export const getToken = (code) => {
  const endpoint = getApiEndpoint('getToken')

  const formData = new FormData()
  formData.append('username', `user${code}`);
  formData.append('password', `pwd${code}`);

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'POST',
      body: formData,
      headers: {
        'device-id': getDeviceIdFromCookie()
      },
      types: [
        GET_TOKEN_REQUEST,
        {
          type: GET_TOKEN_SUCCEDED,
          payload: (action, state, res) => (
            manageResponseFetch(action, state, res)
              .then(({ token }) => {
                console.log('token', token)

                window.EGToken = token
              })
          )
        },
        GET_TOKEN_FAILED
      ]
    }
  }
}
