import React from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import { withLocale } from '../providers'
import generatedMessages from '../locale'

const intlWrapper = ({ locale: stateLocale, children }) => (
  <IntlProvider
    locale={stateLocale}
    messages={generatedMessages[stateLocale]}
  >
    {children}
  </IntlProvider>
)

intlWrapper.propTypes = {
  locale: PropTypes.string.isRequired,
  children: PropTypes.shape().isRequired,
}

const intlRedux = withLocale(intlWrapper)

export default intlRedux
