import { RSAA } from 'redux-api-middleware'
import idx from 'idx'
import { getApiEndpoint } from '../utils/ApiUrls'
import { manageResponseFetch, updateOrdersWithGoCourseStatus, getDefaultHeaders } from '../utils/Functions'

export const GET_BEVERAGE_ORDER_REQUEST = 'beverage/GET_ORDER_REQUEST'
export const GET_BEVERAGE_ORDER_SUCCEDED = 'beverage/GET_ORDER_SUCCEDED'
export const GET_BEVERAGE_ORDER_FAILED = 'beverage/GET_ORDER_FAILED'

export const CREATE_BEVERAGE_ORDER_REQUEST = 'beverage/CREATE_ORDER_REQUEST'
export const CREATE_BEVERAGE_ORDER_SUCCEDED = 'beverage/CREATE_ORDER_SUCCEDED'
export const CREATE_BEVERAGE_ORDER_FAILED = 'beverage/CREATE_ORDER_FAILED'

export const GO_BEVERAGE_ORDER_REQUEST = 'beverage/GO_ORDER_REQUEST'
export const GO_BEVERAGE_ORDER_SUCCEDED = 'beverage/GO_ORDER_SUCCEDED'
export const GO_BEVERAGE_ORDER_FAILED = 'beverage/GO_ORDER_FAILED'

export const getBeverageOrders = ({ tableId }) => {
  const endpoint = getApiEndpoint('createBeverageOrder', { tableId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      headers: {
        ...getDefaultHeaders()
      },
      method: 'GET',
      types: [
        GET_BEVERAGE_ORDER_REQUEST,
        {
          type: GET_BEVERAGE_ORDER_SUCCEDED,
          payload: (action, state, res) => (
            new Promise((resolve, reject) => {
              manageResponseFetch(action, state, res)
                .then((orders) => {
                  resolve({
                    orders,
                    tableId,
                  })
                })
            })
          )
        },
        GET_BEVERAGE_ORDER_FAILED
      ]
    }
  }
}

export const createNewBeverageOrder = ({ tableId, courses }) => {
  const endpoint = getApiEndpoint('createBeverageOrder', { tableId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'POST',
      headers: {
        ...getDefaultHeaders()
      },
      body: JSON.stringify({
        courses,
        tableId,
      }),
      types: [
        CREATE_BEVERAGE_ORDER_REQUEST,
        {
          type: CREATE_BEVERAGE_ORDER_SUCCEDED,
          payload: (action, state, res) => (
            new Promise((resolve, reject) => {
              manageResponseFetch(action, state, res)
                .then((order) => {
                  resolve({
                    order,
                    tableId,
                  })
                })
            })
          )
        },
        CREATE_BEVERAGE_ORDER_FAILED
      ]
    }
  }
}


export const goBeverageOrder = ({ courseId, tableId }) => {
  const endpoint = getApiEndpoint('goCourse', { courseId })

  return {
    [RSAA]: {
      endpoint: `${endpoint}`,
      method: 'POST',
      headers: {
        ...getDefaultHeaders()
      },
      body: JSON.stringify({
        tableId,
        courseId,
      }),
      types: [
        {
          type: GO_BEVERAGE_ORDER_REQUEST,
          payload: (action, state, res) => ({
            tableId,
            courseId,
          })
        },
        {
          type: GO_BEVERAGE_ORDER_SUCCEDED,
          payload: (action, state, res) => (
            new Promise((resolve, reject) => {
              manageResponseFetch(action, state, res)
                .then((course) => {
                  resolve({
                    course,
                    tableId,
                    courseId,
                  })
                })
            })
          )
        },
        GO_BEVERAGE_ORDER_FAILED
      ]
    }
  }
}

export const beverageOrders = (state = {}, { type, payload }) => {
  const newState = {
    ...state,
  }

  switch (type) {
    case GET_BEVERAGE_ORDER_SUCCEDED:
      newState[payload.tableId] = payload.orders
      return newState
    case GO_BEVERAGE_ORDER_SUCCEDED:
      newState[payload.tableId] = updateOrdersWithGoCourseStatus(
        state[payload.tableId],
        payload.courseId,
        payload.course
      )
      return newState
    case GO_BEVERAGE_ORDER_REQUEST:
      newState[payload.tableId] = updateOrdersWithGoCourseStatus(
        state[payload.tableId],
        payload.courseId,
        {
          status: 'loading'
        }
      )
      return newState
    default:
      return state
  }
}
