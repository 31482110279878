import { connect } from 'react-redux'
import idx from 'idx'
import { getBookings } from '../data/Bookings'

const mapStateToProps = (state) => ({
  bookings: state.bookings,
  loading: idx(state, s => s.loading.bookings),
})

const mapDispatchToProps = (dispatch) => ({
  getBookings: () => dispatch(getBookings()),
})

const withBookingsPage = component => connect(mapStateToProps, mapDispatchToProps)(component)

export default withBookingsPage
