import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  withRouter,
  Route,
  Switch,
  Link,
  matchPath,
} from 'react-router-dom'
import {
  Layout,
  Icon,
  Menu,
  PageHeader,
  Row,
  Col,
} from 'antd'
import Clock from 'react-live-clock';
import idx from 'idx';
import { FormattedMessage } from 'react-intl'
import {
  TablesArea,
} from '../../components'
import {
  ReservationsPage,
} from '../../pages'
import {
  withUser
} from '../../providers'
import s from './Routes.styl'

const {
  Sider,
} = Layout

const menuIconStyle = {
  fontSize: '27px',
  marginRight: 0,
}

class Routes extends Component {
  constructor(props) {
    super(props)

    this.state = {
      matches: []
    }
  }

  componentDidMount() {
    const { getOptions } = this.props

    getOptions()
    this.getSelectedKeys()
  }

  componentDidUpdate(oldProps) {
    const { location } = this.props

    if (location.pathname !== oldProps.location.pathname) {
      this.getSelectedKeys()
    }
  }

  getSelectedKeys = () => {
    const { location } = this.props

    const routes = [{
      path: '/tables',
      exact: false,
    }, {
      path: '/reservations',
      exact: false,
    }]
  
    const selecterPaths = routes.filter((route) => {
      const match = matchPath(location.pathname, route)
  
      return match !== null
    })

    const matches = selecterPaths.map((route) => route.path)

    this.setState({
      matches
    })
  }

  handleLogout = () => {
    const { history } = this.props

    window.EGToken = undefined
    history.push('/login')
  }

  render() {
    const { matches } = this.state
    const { user, loading } = this.props

    return (
      <Layout className={s.mainWrapper} style={{ minHeight: '100%' }}>
        <Layout>
          <Layout
            style={{
              height: '100%',
              minHeight: '100vh',
            }}
          >
            <PageHeader
              className={s.pageHeader}
            >
              <Row align="middle">
                <Col span={12}>
                  <Menu
                    mode="horizontal"
                    className={s.menu}
                    selectedKeys={matches}
                    style={{ height: '100%', borderRight: 0 }}
                  >
                    <Menu.Item
                      key="/tables"
                    >
                      <Icon
                        type="appstore"
                        style={menuIconStyle}
                      />
                      <div className={s.menuItemText}>
                        <FormattedMessage id="tablesPage" />
                      </div>
                      <Link to="/tables" />
                    </Menu.Item>
                    <Menu.Item
                      key="/reservations"
                    >
                      <Icon
                        type="calendar"
                        style={menuIconStyle}
                      />
                      <div className={s.menuItemText}>
                        <FormattedMessage id="reservationPage" />
                      </div>
                      <Link to="/reservations" />
                    </Menu.Item>
                  </Menu>
                </Col>

                <Col span={12}>
                  <div className={s.headerDateTime}>
                    <div className={s.dateTimeWrapper}>
                      <FormattedMessage id="user" />
                      {' '}
                      {
                        loading && (
                          <Icon type="loading" style={{ fontSize: 16 }} spin />
                        )
                      }
    
                      {
                        !loading && idx(user, u => u.id) && (
                          <Fragment>
                            { user.name }
                            {' '}
                            { user.surname }
                          </Fragment>
                        )
                      }
                    </div>
                    <div className={s.dateTimeWrapper}>
                      <FormattedMessage id="hour" />
                      {' '}
                      <Clock format="HH:mm:ss" ticking />
                    </div>
                    <div className={s.dateTimeWrapper}>
                      <FormattedMessage id="date" />
                      {' '}
                      <Clock format="D.M.Y" ticking />
                    </div>
                  </div>
                </Col>
              </Row>
              <Fragment>
                <button
                  onClick={this.handleLogout}
                  className={s.logoutButton}
                >
                  <Icon type="poweroff" />
                </button>
              </Fragment>
            </PageHeader>
            <Switch>
              <Route path="/tables/:tableId?/:subpage?" component={TablesArea} />
              <Route exact path="/reservations" component={ReservationsPage} />
            </Switch>
  
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

Routes.propTypes = {
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
  getOptions: PropTypes.func.isRequired
}

Routes.defaultProps = {
  loading: false,
}


export default withRouter(withUser(Routes))
