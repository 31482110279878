import Cookies from 'js-cookie'

export const manageResponseFetch = (action, state, res) => {
  const contentType = res.headers.get('Content-Type')
  if (res.status >= 400 || !res.ok || res.error) throw new Error('api reject')
  if (contentType && contentType.indexOf('json')) {
    return res.json()
  }
  return res
}

export const updateOrdersWithGoCourseStatus = (orders, courseId, newCourse) => (
  orders.map((order) => {
    const found = order.courses.findIndex((c) => courseId === c.id)

    
    if (found !== -1 && newCourse) {
      order.courses[found] = {
        ...order.courses[found],
        ...newCourse
      }
    }


    return order
  })
)

export const cleanCoursesByEmpty = (courses) => (
  courses.filter(value => (
    Object.keys(value).length !== 0
  ))
)

export const getDeviceIdFromCookie = () => Cookies.get('device-id') || ''

export const getDefaultHeaders = () => {
  const token = window.EGToken

  const headers = {
    'device-id': getDeviceIdFromCookie(),
    Authorization: `Bearer ${token}`,
  }

  return headers
}
