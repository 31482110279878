import React, { Component } from 'react'
import { uid } from 'react-uid'
import PropTypes from 'prop-types'
import idx from 'idx'
import {
  FormattedMessage,
  FormattedNumber,
  injectIntl
} from 'react-intl'
import {
  Layout,
  Button,
  Drawer,
  DatePicker,
  TimePicker,
  Row,
  Col,
} from 'antd'
import {
  NumericRadioInput,
} from '..'
import {
  ClientSelectionForm,
} from '../../containers'
import s from './EditBookingDrawer.styl'
import { bookings } from '../../data/Bookings'

const timeFormat = 'HH:mm'

class EditBookingDrawer extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.booking !== this.props.booking) {
      this.refreshBookingState(this.props.booking)
    }
  }

  refreshBookingState = (booking) => {
    this.setState({
      booking
    })
  }

  onChangeDate = (date) => {
    console.log('date', date)
  }

  onChangeTime = (time) => {
    console.log('time', time)
  }

  handleChangeSeats = (seats) => {
    console.log('seats', seats)
  }

  handleSelectClient = (client) => {
    const { booking } = this.state

    console.log('handleSelectClient', client)

    this.setState({
      booking: {
        ...booking,
        person: {
          ...booking.person,
          client
        }
      }
    })
  }

  handleUpdateClient = (client) => {
    console.log('handleUpdateClient', client)
  }

  render() {
    const {
      booking
    } = this.state

    const {
      visible,
      onClose,
      intl,
    } = this.props

    const drawerTitle = booking ? intl.formatMessage({ id: 'editBooking' }) : intl.formatMessage({ id: 'addBooking' })

    return (
      <div>
        <Drawer
          title={drawerTitle}
          placement="right"
          closable={false}
          maskClosable={false}
          destroyOnClose
          width={600}
          visible={visible}
          maskClosable={false}
          closable={false}
          className={s.drawer}
          onClose={onClose}
        >
          <Layout
            className={s.mainWrapper}
          >

            <NumericRadioInput
              maxValue={12}
              value={idx(booking, b => b.seats) || 1}
              onChange={this.handleChangeSeats}
            />

            <Row
              gutter={16}
              type="flex"
            >
              <Col>
                <DatePicker onChange={this.onChangeDate} />
              </Col>
              <Col>
                <TimePicker
                  minuteStep={5}
                  onChange={this.onChangeTime}
                  format={timeFormat}
                />
              </Col>
            </Row>

            <ClientSelectionForm
              client={idx(booking, b => b.person.client) || null}
              fetching={false}
              handleUpdateClient={this.handleUpdateClient}
              onChange={this.handleSelectClient}
            />

          </Layout>
        </Drawer>
      </div>
    )
  }
}

EditBookingDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  intl: PropTypes.shape({}).isRequired,
  booking: PropTypes.shape({}),
}
EditBookingDrawer.defaultProps = {
  visible: false,
  onClose: () => {},
  booking: null,
}

export default injectIntl(EditBookingDrawer)
