import React, { Component, Fragment } from 'react'
import idx from 'idx'
import { uid } from 'react-uid'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withRouter, Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Skeleton,
  message,
  notification,
} from 'antd'
import {
  ButtonPlaceholder,
  NewFoodOrderDrawer,
  NewBeverageOrderDrawer,
  OrderCard,
} from '../../components'
import s from './TableOrders.styl'
import {
  withTableOrders
} from '../../providers'
import { ReactComponent as PlusICon } from './plus.svg'

const calculateCoursesQuantity = (courses) => {
  let quantity = 0
  courses.map((course) => {
    Object.keys(course).map((k) => {
      quantity += course[k].quantity 
    })
  })

  return quantity
}

const prepareCourseToApi = (course) => {
  const output = Object.keys(course).map((key) => {
    const product = course[key]
    
    return {
      product: {
        id: key,
      },
      quantity: product.quantity,
      comment: product.comment,
    }
  })

  return output
}

class TableOrders extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      foodDrawer: false,
      beverageDrawer: false,
      initialFoodOrder: null,
    }
  }

  componentDidMount() {
    const {
      getFoodAreas,
      getBeverageAreas,
      getFoodOrders,
      getBeverageOrders,
      match,
    } = this.props

    const { tableId } = match.params

    getFoodAreas()
    getBeverageAreas()

    getFoodOrders({ tableId })
    getBeverageOrders({ tableId })
  }

  handleOpenFoodDrawer = (state) => {
    this.setState({
      foodDrawer: true,
      initialFoodOrder: state || null,
    })
  }

  handleCloseFoodDrawer = () => {
    this.setState({
      foodDrawer: false
    })
  }

  handleOpenBeverageDrawer = (state) => {
    this.setState({
      beverageDrawer: true,
      initialFoodOrder: state || null,
    })
  }

  handleCloseBeverageDrawer = () => {
    this.setState({
      beverageDrawer: false
    })
  }

  handleConfirmFoodOrder = ({ courses, orderId }) => {
    const {
      createNewFoodOrder,
      getFoodOrders,
      intl,
      match: {
        params: {
          tableId
        }
      },
    } = this.props

    if (calculateCoursesQuantity(courses) > 0) {
      if (orderId) {
        // updateFoodOrder({
        //   orderId,
        //   table: params.tableId,
        //   courses,
        // })
      } else {
        const hide = message.loading(intl.formatMessage({ id: 'loadingOrder' }), 0)

        const order = {
          tableId,
          courses: courses.map((course) => {
            const productList = prepareCourseToApi(course)

            return {
              productList,
            }
          }),
        }

        createNewFoodOrder(order)
          .then(() => {
            getFoodOrders({ tableId })
            hide()
          })
      }

      this.setState({
        foodDrawer: false,
      })
    } else {
      this.emptyOrderAlert()
    }
  }

  handleConfirmBeverageOrder = ({ courses, orderId }) => {
    const {
      createNewBeverageOrder,
      getBeverageOrders,
      intl,
      match: {
        params: {
          tableId
        }
      },
    } = this.props

    if (calculateCoursesQuantity(courses) > 0) {
      if (orderId) {
        // updateFoodOrder({
        //   orderId,
        //   table: params.tableId,
        //   courses,
        // })
      } else {
        const hide = message.loading(intl.formatMessage({ id: 'loadingOrder' }), 0)

        const order = {
          tableId,
          courses: courses.map((course) => {
            const productList = prepareCourseToApi(course)

            return {
              productList,
            }
          }),
        }

        createNewBeverageOrder(order)
          .then(() => {
            getBeverageOrders({ tableId })
            hide()
          })
      }
  
      this.setState({
        beverageDrawer: false,
      })
    } else {
      this.emptyOrderAlert()
    }
  }

  emptyOrderAlert = () => {
    const { intl } = this.props

    notification.error({
      message: intl.formatMessage({ id: 'ErrorOrderWithoutQuantity' }),
      duration: 3,
    });
  }

  goCourse = (type, courseId) => {
    const {
      goBeverageOrder,
      goFoodOrder,
      match: {
        params: {
          tableId,
        }
      },
    } = this.props

    const args = {
      tableId,
      courseId,
    }

    if (type === 'beverage') {
      goBeverageOrder(args)
    } else if (type === 'food') {
      goFoodOrder(args)
    }
  }
  
  render() {
    const {
      loading,
      foodDrawer,
      beverageDrawer,
      initialFoodOrder,
    } = this.state

    const {
      foodAreas,
      foodOrders,
      foods,
      beverageAreas,
      beverages,
      beverageOrders,
      table,
      match: {
        params
      },
    } = this.props

    const tableFoodOrders = foodOrders[params.tableId]
    const tableBeverageOrders = beverageOrders[params.tableId]

    if (loading) {
      return (
        <div>
          <Skeleton loading active />
        </div>
      )
    }

    if (idx(table, t => t.status !== 'in-use')) {
      return (
        <Redirect to={`/tables/${params.tableId}/status`} />
      )
    }

    return (
      <div
        id="order-drower-container"
        className={s.wrapper}
      >
        <div className={s.inner}>
          <Row gutter={30}>
            <Col span={12}>
              <h5 className={s.title}>
                <FormattedMessage id="food" />
              </h5>

              <div className={s.buttonPlaceholder}>
                <ButtonPlaceholder
                  onClick={() => { this.handleOpenFoodDrawer() }}
                >
                  <Fragment>
                    <PlusICon height={12} className={s.plus} />
                    <FormattedMessage id="addOrder" />
                  </Fragment>
                </ButtonPlaceholder>
              </div>

              {
                tableFoodOrders && tableFoodOrders.length > 0
                  && (
                    tableFoodOrders.map((order, index) => (
                      <OrderCard
                        key={uid(`${order.id}-${index}`)}
                        number={tableFoodOrders.length - index}
                        order={order}
                        handleGoCourse={(courseId) => {
                          this.goCourse('food', courseId)
                        }}
                      />
                    ))
                  )
              }
              
            </Col>
            <Col span={12}>
              <h5 className={s.title}>
                <FormattedMessage id="beverage" />
              </h5>
              <div className={s.buttonPlaceholder}>
                <ButtonPlaceholder
                  onClick={() => { this.handleOpenBeverageDrawer() }}
                >
                  <Fragment>
                    <PlusICon height={12} className={s.plus} />
                    <FormattedMessage id="addOrder" />
                  </Fragment>
                </ButtonPlaceholder>
              </div>
              {
                tableBeverageOrders && tableBeverageOrders.length > 0
                  && (
                    tableBeverageOrders.map((order, index) => (
                      <OrderCard
                        type="beverage"
                        key={uid(`${order.id}-${index}`)}
                        number={tableBeverageOrders.length - index}
                        order={order}
                        handleGoCourse={(courseId) => {
                          this.goCourse('beverage', courseId)
                        }}
                      />
                    ))
                  )
              }
            </Col>
          </Row>

          <NewFoodOrderDrawer
            visible={foodDrawer}
            foodAreas={foodAreas}
            foods={foods}
            handleCloseDrawer={this.handleCloseFoodDrawer}
            handleConfirmOrder={this.handleConfirmFoodOrder}
            initialState={initialFoodOrder}
          />

          <NewBeverageOrderDrawer
            visible={beverageDrawer}
            beverageAreas={beverageAreas}
            beverages={beverages}
            handleCloseDrawer={this.handleCloseBeverageDrawer}
            handleConfirmOrder={this.handleConfirmBeverageOrder}
            initialState={null}
          />
        </div>
      </div>
    )
  }
}

TableOrders.propTypes = {
  foods: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  foodAreas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  beverages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  beverageAreas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  foodOrders: PropTypes.shape().isRequired,
  beverageOrders: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
  getFoodAreas: PropTypes.func.isRequired,
  getBeverageAreas: PropTypes.func.isRequired,
  getFoodOrders: PropTypes.func.isRequired,
  getBeverageOrders: PropTypes.func.isRequired,
  createNewFoodOrder: PropTypes.func.isRequired,
  createNewBeverageOrder: PropTypes.func.isRequired,
  goFoodOrder: PropTypes.func.isRequired,
  goBeverageOrder: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,
  table: PropTypes.shape({}).isRequired,
}
TableOrders.defaultProps = {
}

export default injectIntl(withRouter(withTableOrders(TableOrders)))
