import React from 'react';
import ReactDOM from 'react-dom';
import '@babel/polyfill'
import App from './bootstrap/App';
import { unregister } from './serviceWorker';
import '!style-loader!css-loader!normalize.css' //eslint-disable-line
import 'antd/dist/antd.less' //eslint-disable-line
// import '!style-loader!css-loader!./style/antd.css' //eslint-disable-line
import './style/main.styl'

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
