import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import {
  Table,
  Button
} from 'antd'
import s from './BookingsList.styl'

const reorderBooking = (booking) => (
  {
    name: `${booking.name} ${booking.surname}`,
    seats: booking.seats,
    date: moment(booking.arrivalDate).format('DD/MM/Y'),
    hour: moment(booking.arrivalTime).format('HH:ss'),
    client: booking,
    table: booking.assignedTable ? booking.assignedTable : '—',
    originalBooking: booking,
  }
)

const BookingsList = ({
  bookings,
  intl,
  action,
  actionText,
  showTable,
}) => (
  <div>
    <Table
      className={s.table}
      pagination={false}
      rowClassName={s.tableRow}
      dataSource={bookings.map((b) => reorderBooking(b))}
    >
      <Table.Column title={intl.formatMessage({ id: 'name' })} dataIndex="name" key="name" />
      <Table.Column title={intl.formatMessage({ id: 'seats' })} dataIndex="seats" key="seats" />
      <Table.Column title={intl.formatMessage({ id: 'date' })} dataIndex="date" key="date" />
      <Table.Column title={intl.formatMessage({ id: 'hour' })} dataIndex="hour" key="hour" />
      {
        showTable
        && <Table.Column title={intl.formatMessage({ id: 'table' })} dataIndex="table" key="table" />
      }
      {
        action
      && <Table.Column
        key="action"
        render={(text, record, index) => (
          <span>
            <Button block onClick={() => { action(record.originalBooking) }}>
              {actionText}
            </Button>
          </span>
        )}
      />
      }
    </Table>
  </div>
)

BookingsList.propTypes = {
  bookings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  action: PropTypes.func,
  showTable: PropTypes.bool,
  actionText: PropTypes.string,
  intl: PropTypes.shape().isRequired,
}
BookingsList.defaultProps = {
  showTable: false,
  action: null,
  actionText: null
}

export default injectIntl(BookingsList)
